import React, { Component } from 'react';
import styled from 'styled-components';
import Gs from './../Theme/globalStyles';
import ReactTooltip from 'react-tooltip';
import Collapse from '@kunukn/react-collapse';

import BNBImg from '../Assets/images/selectTkn-02.png'
import UniswapImg from '../Assets/images/selectTkn-07.png'
import RadioBNB from '../Assets/images/bnbRedioICON.png'
import RadioETH from '../Assets/images/ethRedioICON.png'
import TableIcon01 from '../Assets/images/tableICON-01.png' 
import TableIcon02 from '../Assets/images/tableICON-02.png' 
import TableIcon03 from '../Assets/images/tableICON-03.png' 
import TableIcon04 from '../Assets/images/tableICON-04.png' 
import TableIcon05 from '../Assets/images/tableICON-05.png' 
import TableIcon06 from '../Assets/images/tableICON-06.png' 
import TableIcon07 from '../Assets/images/tableICON-07.png' 
import TableIcon08 from '../Assets/images/tableICON-08.png' 
import TableIcon09 from '../Assets/images/tableICON-09.png' 
import TableIcon010 from '../Assets/images/tableICON-010.png' 
import TableIcon011 from '../Assets/images/tableICON-011.png' 

class Page07 extends Component {


    constructor() {
        super();
        this.state = {
            popup01: false,
            popup02: false,
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: true,
            isOpen6: false

        };
    }

    toggle = index => {
        let collapse = 'isOpen' + index;

        this.setState(prevState => ({ [collapse]: !prevState[collapse] }));
    };
    render() {


        return (
            <>
                <Gs.Container>

                    <MultPolTitle01>
                        Multi Pools on Multi DEXs
                        <span>One-click to open or add liquidity to all DEXs</span>
                    </MultPolTitle01>

                    <MPSearchBar>

                        <MPSbx01 className='sb-BorRight'>
                            <MPSSbox01>
                                <i className="fas fa-search"></i> Search by
                            </MPSSbox01>
                            <MPSSbox02>
                                <input placeholder='DEX, token, face value...' />
                                <FilterBTN onClick={() => this.toggle(5)}><i className="fas fa-filter"></i></FilterBTN>
                            </MPSSbox02>
                        </MPSbx01>
                        <MPSbx02>
                            <SwapBTN01 className='orangeBack'>ADD YOUR DEX</SwapBTN01>
                        </MPSbx02>
                        <Collapse isOpen={this.state.isOpen5} className="collapse-css-transition" >
                            <MPSearchFilterMbx>


                                <MPSbx02>
                                    <DDMenuMbox >
                                        <DDMenuBTN onClick={() => this.toggle(1)}>
                                            <span>Token symbol</span> <i className='fas fa-caret-down'></i>
                                        </DDMenuBTN>
                                        <DDMenuContainer>
                                            <Collapse isOpen={this.state.isOpen1} className="collapse-css-transition" >
                                                <DDSubBTN>
                                                    <i> <img src={BNBImg} alt="" />  </i>   BNB
                                        </DDSubBTN>
                                                <DDSubBTN>
                                                    <i> <img src={UniswapImg} alt="" />  </i>   UNISWap
                                        </DDSubBTN>
                                                <DDSubBTN>
                                                    <i> <img src={BNBImg} alt="" />  </i>   BNB
                                        </DDSubBTN>
                                            </Collapse>
                                        </DDMenuContainer>
                                    </DDMenuMbox>

                                    <DDInputBX01 placeholder='Type the token symbol here' className='disaBled' />

                                </MPSbx02>

                                <MPSbx02>
                                    <DDMenuMbox >
                                        <DDMenuBTN onClick={() => this.toggle(2)}>
                                            <span>APY</span> <i className='fas fa-caret-down'></i>
                                        </DDMenuBTN>
                                        <DDMenuContainer>
                                            <Collapse isOpen={this.state.isOpen2} className="collapse-css-transition" >
                                                <DDSubBTN>
                                                    API 01
                                        </DDSubBTN>
                                                <DDSubBTN>
                                                    API 02
                                        </DDSubBTN>
                                                <DDSubBTN>
                                                    API 03
                                        </DDSubBTN>
                                            </Collapse>
                                        </DDMenuContainer>
                                    </DDMenuMbox>
                                    <DDInputBX01 placeholder='Type the minimum APY here' className='disaBled' />
                                </MPSbx02>
                                <MPSbx02>
                                    <DDMenuMbox >
                                        <DDMenuBTN onClick={() => this.toggle(3)}>
                                            <span>Staking period</span> <i className='fas fa-caret-down'></i>
                                        </DDMenuBTN>
                                        <DDMenuContainer>
                                            <Collapse isOpen={this.state.isOpen3} className="collapse-css-transition" >
                                                <DDSubBTN>
                                                    Staking period  30day
                                        </DDSubBTN>
                                                <DDSubBTN>
                                                    Staking period  60day
                                        </DDSubBTN>
                                                <DDSubBTN>
                                                    Staking period  90day
                                        </DDSubBTN>
                                            </Collapse>
                                        </DDMenuContainer>
                                    </DDMenuMbox>



                                    <DDMenuMbox style={{ marginTop: '15px' }} className='disaBled'>
                                        <DDMenuBTN onClick={() => this.toggle(4)}>
                                            <span>Choose max staking days</span> <i className='fas fa-caret-down'></i>
                                        </DDMenuBTN>
                                        <DDMenuContainer>
                                            <Collapse isOpen={this.state.isOpen4} className="collapse-css-transition" >
                                                <DDSubBTN>
                                                    Staking period  30day
                                        </DDSubBTN>
                                                <DDSubBTN>
                                                    Staking period  60day
                                        </DDSubBTN>
                                                <DDSubBTN>
                                                    Staking period  90day
                                        </DDSubBTN>
                                            </Collapse>
                                        </DDMenuContainer>
                                    </DDMenuMbox>

                                </MPSbx02>

                                <MPSbx02>
                                    <div className="md-checkbox">
                                        <input type="checkbox" id="radio137" name="stepin47" defaultChecked defaultValue="option137" />
                                        <label htmlFor="radio137">Show only DEXs with values</label>
                                    </div>
                                    <div className="md-checkbox">
                                        <input type="checkbox" id="radio138" name="stepin47" defaultValue="option137" />
                                        <label htmlFor="radio138">Show DEXs with my pools</label>
                                    </div>

                                    <MPSsubBox>
                                        <MPSsubBox02>
                                            <button className="active"><i className="fas fa-square"></i></button>
                                            <button><i className="fas fa-th-list"></i></button>

                                        </MPSsubBox02>
                                        <MPSsubBox02>
                                            <div className="md-checkbox inline leftS">
                                                <input type="checkbox" id="r11" name="rpp1" defaultChecked defaultValue="rpp1" />
                                                <label htmlFor="r11"><img src={RadioBNB} /></label>
                                            </div>
                                            <div className="md-checkbox inline leftS">
                                                <input type="checkbox" id="r22" name="rpp1" defaultValue="rpp1" />
                                                <label htmlFor="r22"><img src={RadioETH} /></label>
                                            </div>
                                        </MPSsubBox02>
                                    </MPSsubBox>

                                </MPSbx02>




                            </MPSearchFilterMbx>


                        </Collapse>
                    </MPSearchBar>

                    <MultPolTitle02>
                        Choose DEXs to open pool with
                    </MultPolTitle02>

                    <DexMBox>
                        <MPSbx02 className="RinfoBX">
                            <MPSubtitle01> Place your token contract</MPSubtitle01>
                            <DDInputBX01 defaultValue="0x084374b06891f741..." />
                            <DDinputInfoBXR>
                                JNTR
                        </DDinputInfoBXR>
                        </MPSbx02>
                        <MPSbx02 className="RinfoBX">
                            <MPSubtitle01> Total supply for Token A</MPSubtitle01>

                            <DDInputBX01 defaultValue="10,000,000" />
                            <DDinputInfoBXR>
                                JNTR
                        </DDinputInfoBXR>
                        </MPSbx02>
                        <MPSbx02 className="LinfoBX02">
                            <MPSubtitle01>Total supply for token B</MPSubtitle01>
                            <DDInputBX01 defaultValue="10,000" />

                            <DDinputInfoBXL02>

                                <DDbtn01>
                                    <button onClick={() => this.toggle(6)}>
                                        <img src={RadioETH} alt="" />      <i className="fas fa-sort-down"></i>
                                    </button>
                                </DDbtn01>

                                <div className="ddSubWindow">
                                    <Collapse isOpen={this.state.isOpen6} className="collapse-css-transition" >
                                        <button>
                                            <img src={RadioBNB} alt="" />
                                        </button>
                                    </Collapse>
                                </div>


                            </DDinputInfoBXL02>
                        </MPSbx02>
                        <MPSbx02 className="LinfoBX">

                            <MPSubtitle01>Initiate token face value</MPSubtitle01>
                            <DDInputBX01 defaultValue="0.01" />

                            <DDinputInfoBXL> $ </DDinputInfoBXL>

                            <DDInputInfoBX02>[Ξ 0.00000485]</DDInputInfoBX02>

                        </MPSbx02>
                    </DexMBox>

                    <TableBX01>
                    <table width="100%" border="0" cellSpacing="0" cellPadding="10px">
                                <tbody>
                                        <tr>
                                                <th width="48" align="left" valign="middle" scope="col">All</th>
                                                <th align="left" valign="middle" scope="col">DEXs</th>
                                                <th colSpan="2" align="left" valign="middle" scope="col">Token A</th>
                                                <th colSpan="2" align="left" valign="middle" scope="col">Token B</th>
                                                <th align="left" valign="middle" scope="col">Token face value</th>
                                                <th align="left" valign="middle" scope="col">Listing cost</th>
                                                <th align="left" valign="middle" scope="col"> </th>
                                        </tr>
                                        <tr>
                                                <td align="left" valign="middle">
                                                <div className="md-checkbox inline leftS">
                                                    <input type="checkbox" defaultChecked id="r20" name="rpp2" defaultValue="rpp2" />
                                                    <label htmlFor="r20"> </label>
                                                </div>
                                                </td>
                                                <td align="left" valign="middle">
                                                <CoinBX>
                                                    <i> <img src={TableIcon01} alt="" /> </i>
                                                    bSWAP
                                                </CoinBX>
                                                </td>
                                                <td align="left" valign="middle">
                                                <CoinBX>
                                                    <i> <img src={TableIcon06} alt="" /> </i>
                                                    JNTR
                                                </CoinBX>
                                                </td>
                                                <td align="left" valign="middle">
                                                    <TableInput01 defaultValue='5' /> 
                                                </td>
                                                <td align="left" valign="middle">
                                                <CoinBX>
                                                    <i> <img src={TableIcon07} alt="" /> </i>
                                                    BNB
                                                </CoinBX>
                                                </td>
                                                <td align="left" valign="middle">
                                                    <TableInput01 defaultValue='10' /> 
                                                </td>
                                                <td align="left" valign="middle">
                                                $0.01 <span className="smText">[Ξ 0.00000485]</span>
                                                </td>
                                                <td align="left" valign="middle" className="orangeColor">Free </td>
                                                <td align="left" valign="middle">
                                                    <TableBTN01>Open Pool</TableBTN01>
                                                 </td>
                                        </tr>
                                        <tr>
                                                <td align="left" valign="middle">
                                                <div className="md-checkbox inline leftS">
                                                    <input type="checkbox" id="r21" name="rpp2" defaultValue="rpp2" />
                                                    <label htmlFor="r21"> </label>
                                                </div>
                                                </td>
                                                <td align="left" valign="middle">
                                                <CoinBX>
                                                    <i> <img src={TableIcon02} alt="" /> </i>
                                                    Uni swap
                                                </CoinBX>
                                                </td>
                                                <td align="left" valign="middle">
                                                <CoinBX>
                                                    <i> <img src={TableIcon06} alt="" /> </i>
                                                    JNTR
                                                </CoinBX>
                                                </td>
                                                <td align="left" valign="middle">
                                                    <TableInput01 defaultValue='200' /> 
                                                </td>
                                                <td align="left" valign="middle">
                                                <CoinBX>
                                                    <i> <img src={TableIcon08} alt="" /> </i>
                                                    ETH
                                                </CoinBX>
                                                </td>
                                                <td align="left" valign="middle">
                                                    <TableInput01 defaultValue='5.992' /> 
                                                </td>
                                                <td align="left" valign="middle">
                                                $0.01 <span className="smText">[Ξ 0.00000485]</span>
                                                </td>
                                                <td align="left" valign="middle">  
$10.26 </td>
                                                <td align="left" valign="middle"> <TableBTN01>Open Pool</TableBTN01></td>
                                        </tr>
                                        <tr>
                                                <td align="left" valign="middle">
                                                <div className="md-checkbox inline leftS">
                                                    <input type="checkbox" id="r26" name="rpp2" defaultValue="rpp2" />
                                                    <label htmlFor="r26"> </label>
                                                </div>
                                                </td>
                                                <td align="left" valign="middle">
                                                    <CoinBX>
                                                        <i> <img src={TableIcon03} alt="" /> </i>
                                                        Defi swap
                                                    </CoinBX>
                                                </td>
                                                <td align="left" valign="middle">
                                                    <CoinBX>
                                                        <i> <img src={TableIcon06} alt="" /> </i>
                                                        JNTR
                                                    </CoinBX>
                                                </td>
                                                <td align="left" valign="middle">
                                                    <TableInput01 defaultValue='87' /> 
                                                </td>
                                                <td align="left" valign="middle">
                                                <CoinBX>
                                                    <i> <img src={TableIcon09} alt="" /> </i>
                                                    USDT
                                                </CoinBX>
                                                </td>
                                                <td align="left" valign="middle">
                                                    <TableInput01 defaultValue='88' /> 
                                                </td>
                                                <td align="left" valign="middle">
                                                $0.01 <span className="smText">[Ξ 0.00000485]</span>
                                                </td>
                                                <td align="left" valign="middle">
$0.33</td>
                                                <td align="left" valign="middle"><TableBTN01>Open Pool</TableBTN01> </td>
                                        </tr>
                                        <tr>
                                                <td align="left" valign="middle">
                                                <div className="md-checkbox inline leftS">
                                                    <input type="checkbox" id="r23" name="rpp2" defaultValue="rpp2" />
                                                    <label htmlFor="r23"> </label>
                                                </div>
                                                </td>
                                                <td align="left" valign="middle">
                                                    <CoinBX>
                                                        <i> <img src={TableIcon04} alt="" /> </i>
                                                        Mini swap
                                                    </CoinBX></td>
                                                <td align="left" valign="middle">
                                                    <CoinBX>
                                                        <i> <img src={TableIcon06} alt="" /> </i>
                                                        JNTR
                                                    </CoinBX>
                                                </td>
                                                <td align="left" valign="middle">
                                                    <TableInput01 defaultValue='10,000' /> 
                                                </td>
                                                <td align="left" valign="middle">
                                                <CoinBX>
                                                    <i> <img src={TableIcon010} alt="" /> </i>
                                                    ADA
                                                </CoinBX>
                                                </td>
                                                <td align="left" valign="middle">
                                                    <TableInput01 defaultValue='9' /> 
                                                </td>
                                                <td align="left" valign="middle">
                                                $0.01 <span className="smText">[Ξ 0.00000485]</span>
                                                </td>
                                                <td align="left" valign="middle">
$0.39
</td>
                                                <td align="left" valign="middle"><TableBTN01>Open Pool</TableBTN01> </td>
                                        </tr>
                                        <tr>
                                                <td align="left" valign="middle">
                                                <div className="md-checkbox inline leftS">
                                                    <input type="checkbox" id="r24" name="rpp2" defaultValue="rpp2" />
                                                    <label htmlFor="r24"> </label>
                                                </div>
                                                </td>
                                                <td align="left" valign="middle">
                                                    <CoinBX>
                                                        <i> <img src={TableIcon05} alt="" /> </i>
                                                        Mini swap
                                                    </CoinBX>
                                                </td>
                                                <td align="left" valign="middle">
                                                    <CoinBX>
                                                        <i> <img src={TableIcon06} alt="" /> </i>
                                                        JNTR
                                                    </CoinBX>
                                                </td>
                                                <td align="left" valign="middle">
                                                    <TableInput01 defaultValue='5,201.554' /> 
                                                </td>
                                                <td align="left" valign="middle">
                                                <CoinBX>
                                                    <i> <img src={TableIcon011} alt="" /> </i>
                                                    ANKR
                                                </CoinBX>
                                                </td>
                                                <td align="left" valign="middle">
                                                    <TableInput01 defaultValue='1,982' /> 
                                                </td>
                                                <td align="left" valign="middle">
                                                $0.01 <span className="smText">[Ξ 0.00000485]</span>
                                                </td>
                                                <td align="left" valign="middle">$0.62 </td>
                                                <td align="left" width="140px" valign="middle"><TableBTN01 className="greenBTN">Add liquidity</TableBTN01> </td>
                                        </tr>
                                </tbody>
                            </table>
                    </TableBX01>

                    <MPSBTNBX>
                            <MultiBTN01>Open Multiple Pools</MultiBTN01>
                    </MPSBTNBX>

                </Gs.Container>
                <ReactTooltip effect="solid" className="myTip" />
            </>
        );
    }
}



// Common Style Div 


const FlexDiv = styled.div`
display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;

const TableBX01 = styled.div`
    width:100%; margin:60px 0;

    & table{ border:0px; width:100%;
    
       tr{ border-bottom:1px solid #545861;}
       tr:nth-child(01){ border-top:2px solid #545861; border-bottom:2px solid #545861;}
       th{  }
       td{  } 
       .smText{ display:block; width:100%; font-size:11px; font-weight:300; color:#8e9195; padding-top:3px;}
    }

`

const TableBTN01 = styled.button`
 display:inline-block; text-align:center; width:135px; height:27px; border:2px solid #febb00; color:#febb00; border-radius: 5px;

  & :hover{ background:#febb00; color:#fff;}

  &.greenBTN{ background-color:#8db610; border-color:#8db610; color:#fff;
    
    :hover{background-color:#6f9108;  border-color:#6f9108;}

  }

`

const TableInput01 = styled.input`

    background-color: transparent; width:100%; max-width:110px; border:2px solid #5a5e67; height:36px; padding:6px; color: #fff;


`
const MultPolTitle01 = styled(FlexDiv)`
    width:100%; text-align:center; font:normal 32px/40px 'Press Start 2P', arial; color:#fff; flex-wrap:wrap; margin-bottom:70px; 
    & span{font:normal 18px/25px 'IBM Plex Mono', arial; color:#8e9195; display:block; text-align:center; width:100%; padding-top:30px; } 
`
const MultPolTitle02 = styled(MultPolTitle01)`
    width:100%; text-align:center; font:normal 18px/26px 'Press Start 2P', arial; color:#fff; flex-wrap:wrap; margin:70px 0 50px 0;  
`


const MPSearchBar = styled(FlexDiv)`
    width:100%;  border-top:2px solid #5a5e67; padding:18px 0;  border-bottom:2px solid #5a5e67;  flex-wrap:wrap;

    &>.collapse-css-transition{ overflow:visible !important;} 
`
const DexMBox = styled(FlexDiv)`
`
const MPSbx01 = styled(FlexDiv)`
    width:75%;   padding:0 18px;   flex-wrap:wrap; justify-content:flex-start;
`
const MPSbx02 = styled(MPSbx01)`
    width:25%;   position:relative;  
`



const MPSSbox01 = styled(FlexDiv)`
    width:170px; font:normal 14px/30px 'Press Start 2P', arial; color:#fff; justify-content: space-between;

        & .fas{ font-size:18px;}

`
const SwapBTN01 = styled.button`
    display:flex; align-items:center; justify-content: center; color:#fff; border:none; background-color: #ababab;
    font:300 11px/32px 'Press Start 2P', arial; width:100%; padding:12px 12px; border-radius: 10px;
    :hover{  background-color: #878787;}
    &.darkGray{ background-color:#5d6168;
        :hover{  background-color: #484c53;} }
    &.orangeBack{ background-color:#febb00;
    :hover{  background-color: #ecae00; } 
    }
    &.blackBack{ background-color:#000000;
    :hover{  background-color: #302300; } 
    }
`;

const MPSBTNBX = styled(FlexDiv)`
    width:100%; margin:50px 0 20px 0;
`
const MultiBTN01 = styled.button`
    display:flex; align-items:center; justify-content: center; color:#fff; border:none; background-color: #febb00;
    font:300 24px/35px 'Press Start 2P', arial; width:100%; padding:22px 24px; border-radius: 10px; max-width:660px; margin:0 auto;
    :hover{  background-color: #ecae00;}
    
`;

const MPSSbox02 = styled(FlexDiv)`
    width:calc(100% - 170px); position: relative;

    & input{ width:100%; background-color:transparent; border:0px;  font:normal 14px/30px 'Press Start 2P', arial; color:#fff; padding:5px 50px 5px 12px; }

`
const FilterBTN = styled.button` 
    position:absolute; width: 52px; font-size:18px; right:1px; color:#febb00; 
    :hover {opacity:0.7}

`
const MPSearchFilterMbx = styled(FlexDiv)`
    width:100%; justify-content: flex-start; flex-wrap:wrap; align-items:flex-start;  margin-top:10px; 
`

const DDMenuMbox = styled(FlexDiv)`
    width:100%; justify-content:flex-start; padding:6px 10px; position: relative; height:56px; border:2px solid #5a5e67; border-radius:10px;

`
const DDMenuBTN = styled.button`  
    width:100%; margin:0; padding:8px; display:flex; align-items:center; justify-content: space-between; font-size:14px; font-weight: 700; color:#fff; border:none; outline:none; 
`

const DDMenuBTN02 = styled(DDMenuBTN)` 
    width:100%; margin:0; padding:0; display:flex; align-items:center; justify-content: space-between; font-size:14px; font-weight: 700; color:#fff; border:none; outline:none; 
`

const DDMenuContainer = styled.div`
position: absolute; width:100%; left: 0; right: 0; top:calc(100% + 6px);  background-color: rgba(0,0,0,0.8);  z-index: 10; border-radius: 10px; 
`
const DDSubBTN = styled.button` 
width:100%; margin:8px 10px 8px 10px; background-color:transparent; border:none; outline:none; font-size:16px; color:#fff;  font-weight:400;
display:flex; align-items:center; justify-content:flex-start; padding:8px 10px;

    & i{ width:24px; height:24px; display:flex; align-items:center; justify-content:flex-start; margin-right:10px}

    &:hover{ background-color:#2C2F36;}

`
const DDInputBX01 = styled.input`
width:100%; background-color: transparent; border:2px solid #5a5e67; border-radius:10px; outline:none; padding: 6px 10px;
    position: relative;  height: 56px; margin:15px 0; color: #fff; font-size: 14px; font-weight: 700; 

    ${MPSbx02}.RinfoBX & { 
        padding-right:85px;
    }
    ${MPSbx02}.LinfoBX & { 
        padding-left:50px;
    } 
    ${MPSbx02}.LinfoBX02 & { 
        padding-left:85px;
    } 
`

const MPSubtitle01 = styled(FlexDiv)`
    font-size: 16px; color:#fff;  font-weight:700; padding-bottom:4px ;

`
const DDinputInfoBXR = styled(FlexDiv)`
width:80px; height:56px; background-color:#5a5e67; border-radius:0 10px 10px 0; position:absolute; right: 18px;
    bottom: 15px; font-size:16px; font-weight: 700;

`
const DDinputInfoBXL = styled(DDinputInfoBXR)`
width:53px; height:56px; background-color:#5a5e67; border-radius:10px 0 0 10px; position:absolute; right: auto; left:0px;
    bottom: 15px; font-size:16px; font-weight: 700; 
`

const DDinputInfoBXL02 = styled(DDinputInfoBXL)` 
    width:75px; height:56px; left:19px; 

    
      .ddSubWindow {position: absolute; left:-1px; top: calc(100% - 5px);background-color: #6b707a;min-width:76px;border-radius: 0 0 0 8px;z-index: 11; 

      button{display:flex; align-items: center; justify-content: flex-start; padding: 8px; width:100%; text-align:left; padding-left:12px; filter:brightness(2); min-height:50px; width: 100%;  padding-left: 20px; }
    }


`


const MPSsubBox = styled(FlexDiv)`
    justify-content:space-between; width:100%; margin-top:14px;
`

const DDbtn01 = styled(FlexDiv)`
    justify-content: flex-end; position: relative;

    button{ display:flex; align-items: center; justify-content: center;   color:#fff; 
        & img{ max-width:100%; height:auto; margin-right:8px;}
        &:hover{ opacity:0.8;}
    }  
`
const MPSsubBox02 = styled(FlexDiv)`
    justify-content:space-between; 

    & button { color: #8e9195; font-size:18px; padding:0; margin-right:12px;

        :hover{ color: #febb00; }
        &.active{ color: #febb00;}

    }
`

const DDInputInfoBX02 = styled(FlexDiv)`
    position:absolute; right: 25px; bottom: 34px; font-size: 12px; color:#8e9195; font-weight: 300;
`
const CoinBX = styled(FlexDiv)`
 display: flex; align-items: center; justify-content: flex-start; font-size:16px; font-weight: 400; 
 & i{ width: 30px; height: 30px; margin: 0 10px 0 0; border: 2px solid #fff; background-color: #fff; border-radius: 15px; overflow: hidden;}
 & i img{ width: 100%; height: 100%; object-fit: cover;}

`  



export default Page07;