export const theme = (isDarkTheme) => {
    return {


        // text colors
        color01: isDarkTheme ? '#fff': '#000',


        // background colors
        bodybg01: isDarkTheme ? '#2c2f36': '#fff',
        headbg01: isDarkTheme ? '#303239': '#999999',










    }
}