import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./Component/header";
import Page01 from "./Pages/page01";
import Page02 from "./Pages/page02";
import Page03 from "./Pages/page03";
import Page04 from "./Pages/page04";
import Page05 from "./Pages/page05";
import Page06 from "./Pages/page06";
import Page07 from "./Pages/page07";
import Page08 from "./Pages/page08";
import Gs from "./Theme/globalStyles";
import { ThemeProvider } from "styled-components";
import { theme } from "./Theme/theme";
import Footer from "./Component/footer";
import { CloneContext } from "./contexts/CloneContext";
import axios from "axios";
const selectedTheme = theme(true);

function App() {
  const clone = useContext(CloneContext);
  const [cloneData, setCloneData] = useState();
  const [isloading, setIsloading] = useState(true);
  const [isSubdomain, setIsSubdomain] = useState(false);
  const [noSubDomain, setNoSubDomain] = useState(false);
  const [subDomainName, setSubDomainName] = useState("");

  const getData = async (domain) => {
    setIsloading(true);
    try {
      const res = await axios.get(
        `http://182.72.203.245:1830/api/v1/subdomain/getSubDomain/${domain[0]}`
      );
      if (res.data.response_code === 200) {
        const finalData = {
          bgimage: res.data.result.backGroundImage,
          logoImage: res.data.result.logo,
          subDomain: res.data.result.subDomain,
          primaryColor: res.data.result.primaryColors,
          secondaryColor: res.data.result.seconderyColor,
          fontStyle: res.data.result.fontStyle,
          backgroundOverLay: res.data.result.backgroundOverLay,
          swapButtonColor: res.data.result.swapButton,
          logoUrl: res.data.result.logoUrl,
        };
        const r = document.querySelector(":root");
        r.style.setProperty("--primarycolor", finalData.primaryColor);
        r.style.setProperty("--secondarycolor", finalData.secondaryColor);
        r.style.setProperty("--swap-btn-color", res.data.result.swapButton);
        setCloneData(finalData);
        clone.updateCloneData(finalData, true);
        document.getElementsByTagName("body")[0].classList.add("subdomain");
        document.getElementsByTagName("body")[0].style.fontFamily =
          finalData.fontStyle === "Default"
            ? "Press Start 2P"
            : `${finalData.fontStyle}`;
      } else if (res.data.response_code === 404) {
        setNoSubDomain(true);
      }
      setIsloading(false);
    } catch (error) {
      // console.log(error.response);
      setIsloading(false);
    }
  };

  useEffect(() => {
    const href = window.location.href;
    const domaindata = href
      .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
      .split("/")[0];
    const domain = domaindata.split(".");

    // console.log(domaindata);
    setSubDomainName(domain[0]);

    const localhost = domaindata.indexOf("localhost");
    // console.log(domain.length);
    if (domain.length === (localhost === -1 ? 4 : 2)) {
      clone.updateSubDomain(true);
      getData(domain);
      setIsSubdomain(true);
    } else {
      document.getElementsByTagName("body")[0].classList.remove("subdomain");
      clone.updateSubDomain(false);
      setIsloading(false);
      setIsSubdomain(false);
    }
  }, []);

  return (
    <Router>
      {/* basename={'bscswap_newdark_v2'}   */}
      <ThemeProvider theme={selectedTheme}>
        <section
          className="MainBox clearfix"
          style={
            clone.preview && clone.cloneData.bgimage
              ? {
                  backgroundImage: `url(${clone.cloneData.bgimage})`,
                  backgroundColor: `rgba(0, 0, 0, 0.${clone.cloneData.backgroundOverLay})`,
                }
              : null
          }
        >
          <Gs.GlobalStyle />
          {isloading ? (
            <p>Loading...</p>
          ) : (
            <>
              <Header />
              <Gs.MainBox>
                {!isSubdomain ? (
                  <Switch>
                    <Route path="/page8">
                      <Page08 />
                    </Route>
                    <Route path="/" exact>
                      <Page01 />
                    </Route>
                    <Route path="/page2">
                      <Page02 />
                    </Route>
                    <Route path="/page3">
                      <Page03 />
                    </Route>
                    <Route path="/page4">
                      <Page04 />
                    </Route>
                    <Route path="/page5">
                      <Page05 />
                    </Route>
                    <Route path="/page6">
                      <Page06 />
                    </Route>
                    <Route path="/page7">
                      <Page07 />
                    </Route>
                  </Switch>
                ) : (
                  <Switch>
                    <Route path="/" exact>
                      <Page08 />
                    </Route>
                  </Switch>
                )}
              </Gs.MainBox>
              <Footer />
            </>
          )}
        </section>
      </ThemeProvider>
    </Router>
  );
}

export default App;
