import React, { Component } from 'react';
import styled from 'styled-components';
import Gs from './../Theme/globalStyles';
import ReactTooltip from 'react-tooltip';  
import TokenPopup from '../Component/popup/tokenPopup'
import SelectTokenPopup from '../Component/popup/selectToken'
import TitleIcon01 from './../Assets/images/swapTitleIcon-01.png'
import TitleIcon02 from './../Assets/images/swapTitleIcon-02.png'
import SmbnbIco from './../Assets/images/smBNB-icon.png'
import UnicornIco from './../Assets/images/unicornIco.png'  
import Xbtn from '../Assets/images/closeBTN.png' 

class Page02 extends Component {
    constructor(){
        super();
        this.state = {
            popup01:false,
            popup02:false
        }
    } 
    render() { 
        return ( 
                <>
                <Gs.Container>
                    <Title01>Total Locked Value Over 78 DEXs</Title01>
                    <TimerBox>
                        <span>$</span> <span>2</span>
                        <span>1</span>,
        <span>4</span>
                        <span>5</span>
                        <span>9</span>,
        <span>2</span>
                        <span>5</span>
                        <span>3</span>,
        <span>4</span>
                        <span>2</span>
                        <span>5</span>.
        <span>5</span>
                        <span>3</span>
                    </TimerBox>

                    <SwapMbox01>
                        <SwapSbox01>
                            <SwapSSbox01 className="active">

                                <SwapTitle01>
                                    <img src={TitleIcon01} alt="" />
                                Add Liquidity  <PPClosBTN01 />
                                </SwapTitle01>

                                <SwapBTN01 className="orangeBack">Add Liquidity</SwapBTN01>
                                
                               <SwapTitle05> Your liquidity  <i className="fas helpIco fa-question-circle" data-tip='Content Coming Soon'></i></SwapTitle05>
                                


                                 
                                <SwapInputMbx>
                                     <ConnectWallText>   Connect to a wallet to view  your liquidity </ConnectWallText>
                                </SwapInputMbx>
                                <SSLinkbox01>
                                Don't see the pool you joined?  <a href=''>Import it</a>
                                </SSLinkbox01>

                                
                            </SwapSSbox01>

                            <SwapLinkbx>
                                <a href=''>Manage your liquidity</a> |
                                <a href=''>Add liquidity</a> |
                                <a href=''>Migrate liquidity</a> 
                            </SwapLinkbx>

                        </SwapSbox01>

                        <SwapSbox02>
                            <SwapTitle04>  Swap with </SwapTitle04>

                            <SwapBTN02 onClick={()=>{this.setState({popup01:true})}}>
                                <img src={UnicornIco} className="imgBox01" />
                                <span >Uniswap  <i className="fas fa-caret-down"></i></span>
                            </SwapBTN02>
                             
                            <SwapTitle04  className='smlTitle'>Gas and fees 100% reimbursement with bSWAP <i className="fas helpIco fa-question-circle" data-tip='Content Coming Soon'></i> </SwapTitle04>
                            
                        </SwapSbox02>




                        <SwapSbox01 className="rightAlign">
                            <SwapSSbox01>

                                <SwapTitle01>  <img src={TitleIcon02} alt="" />  Swap </SwapTitle01>
                                <SwapInputMbx>
                                    <SSbx01>
                                        <span>From </span>
                                        <input type='text' defaultValue='0.0' />
                                    </SSbx01>
                                    <SSbx02>
                                        <span>  </span>
                                        <button> <div className="imgBox"><img src={SmbnbIco} alt="" /></div> BNB <i className="fas fa-chevron-down"></i>  </button>
                                    </SSbx02>
                                </SwapInputMbx>
                                <SwapTitle02>
                                <i className="fas fa-arrow-down"></i>
                                </SwapTitle02>
                                <SwapInputMbx>
                                    <SSbx01>
                                        <span>To (estimated) </span>
                                        <input type='text' defaultValue='0.0' />
                                    </SSbx01>
                                    <SSbx02>
                                        <span>  </span>
                                        <button className="OrangeBTN" onClick={()=>{this.setState({popup02:true})}}>
                                            Select Token <i className="fas fa-chevron-down"></i>
                                        </button>
                                    </SSbx02>
                                </SwapInputMbx>
                                <SwapTitle03>
                                Price <span>-</span>
                                </SwapTitle03>

                                <SwapBTN01 className='darkGray'>Connect Wallet</SwapBTN01>
                            </SwapSSbox01>
                            <SwapLinkbx>
                                <a href=''>Market order</a> |
                                <a href=''>Limit order</a> |
                                <a href=''>Analysis</a>  |
                                <a href=''>Settings</a> 
                            </SwapLinkbx>
                        </SwapSbox01>


                    </SwapMbox01>




                
                </Gs.Container>
                <SelectTokenPopup isOpen={this.state.popup02}  dismiss={()=>{this.setState({popup02:false})}} />
                <TokenPopup isOpen={this.state.popup01}  dismiss={()=>{this.setState({popup01:false})}}/>
                <ReactTooltip effect="solid" className="myTip" />
                </>
        );
    }
}



// Common Style Div 
 

const FlexDiv = styled.div`
display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;
  

const Title01 = styled.div`
  font:400 16px/25px 'Press Start 2P', arial; color:#8e9195; margin: 0 0 15px 0;
`;

const TimerBox = styled.div`
  font:400 50px/60px 'Press Start 2P', arial; color:#febb00; margin: 0 0 15px 0;
  display:flex; align-items: center; justify-content:flex-start;

  span{display: inline-block; background-color: #16191e;  padding: 12px 8px; margin: 0 3px; border-radius: 10px; border: 2px solid #5a5e67; text-indent: 6px;}
`;


const SwapMbox01 = styled.div`
   display: flex; align-items: center; justify-content:center; margin:60px 0 0 0;
`;
const SwapSbox01 = styled(FlexDiv)`
   width: 33.33%; justify-content:flex-start ;

  &.rightAlign{ justify-content:flex-end ; }

`;

const SwapSbox02 = styled(FlexDiv)`
   width: 33.33%; padding:15px; flex-direction: column;
 

`;

const SwapSSbox01 = styled.div`
   width:100%; max-width:430px; background-color: #16191e; border: 2px solid #000; min-height: 430px; padding:18px; border-radius: 10px;

   
  &.active{ border-color:#febb00; -webkit-box-shadow: 1px 1px 25px 1px rgba(254,187,0,0.4);
box-shadow: 1px 1px 25px 1px rgba(254,187,0,0.4);}
`;

const SwapTitle01 = styled(FlexDiv)`
  font:300 18px/25px 'Press Start 2P', arial; color:#fff; margin-bottom:24px;  position:relative;

  img{margin:0 16px 0 0; } 
  .helpIco{ position:absolute; right:0px;}
`;

const SwapInputMbx = styled(FlexDiv)`
    border:2px solid #545861; border-radius: 10px; min-height:90px; padding:12px 14px;  
`;

const ConnectWallText = styled(FlexDiv)`
    width:100%; max-width:260px; font-size:14px; color:#565a69; text-align:center; font-weight:400; 
`;

const SSLinkbox01 = styled(FlexDiv)`
    width:100%;  font-size:12px; color:#565a69; text-align:center; font-weight:400; padding:15px 0; 

    & a{ color:#febb00; margin-left:4px;  :hover{ text-decoration:underline;} }
`;

const SSbx01 = styled(FlexDiv)`
    margin:0 auto 0 0; justify-content: flex-start; color:#8e9195; font-size:14px; width: auto; max-width:40%; 
    span{ width:100%; margin:0 0 6px 0; font-weight:700; }
    input{ background-color:transparent; font-weight:700; font-size:26px; width:100%; height:25px; color:#393d46; border:none;} 
`;
const SSbx02 = styled(FlexDiv)`
    margin:0 0 0 auto; justify-content: flex-end; color:#8e9195; font-size:14px; width: auto; max-width:55%; 
    span{ width:100%; margin:0 0 6px 0; text-align:right; min-height:20px;   }  

    button{
        font-size:16px; color:#fff;  font-weight: 700; display:flex; align-items: center; justify-content:space-between;
    }

    button.OrangeBTN{ background-color:#febb00; font-size:16px; color:#fff; font-weight:500; padding:6px 12px; border-radius:6px;
    
    .fas{font-size:14px;}
    &:hover{ background-color:#e4a800;}
    
    }

    .maxTitle{ background-color:#faecc5;  margin:0 0 0 8px; font-size:12px; border-radius:3px; padding:2px 5px; color:#000000; display:inline-block; } 
    .imgBox{ display:inline-block; margin:0 10px; padding-top:6px; }
    .fas { margin-left:8px;}
`;
const SwapTitle02 = styled(FlexDiv)`
  font:300 18px/25px 'Press Start 2P', arial; color:#565a69;  padding:18px 0; justify-content:flex-start;
  
    &.Center{ justify-content:center;}

`;
const SwapTitle03 = styled(FlexDiv)`
  font-size:14px; color:#8e9195; font-weight:600; min-height:42px;   justify-content:flex-start;
    span{ margin-left:auto;}
`;


const SwapTitle04 = styled(FlexDiv)`
  font:300 18px/40px 'Press Start 2P', arial; color:#fff; margin-bottom:20px;    justify-content:flex-start; 


    &.smlTitle{  font:300 11px/20px 'IBM Plex Mono', arial; color:#8e9195; margin-top:20px;}

`;

const SwapTitle05 = styled(FlexDiv)`
 font-size:18px; font-weight:700; color:#fff; margin:25px 0 15px 0;    justify-content:flex-start;  

 & .helpIco{ font-size:14px; margin-left:auto; right:3px; top:0px;}
`;

const SwapBTN01 = styled.button`
    display:flex; align-items:center; justify-content: center; color:#fff; border:none; background-color: #ababab;
    font:300 16px/32px 'Press Start 2P', arial; width:100%; padding:12px 12px; border-radius: 10px;

    :hover{  background-color: #878787;}

    &.darkGray{ background-color:#5d6168;
        :hover{  background-color: #484c53;} }

    &.orangeBack{ background-color:#febb00;
    :hover{  background-color: #ecae00; 
    } }

`;

const SwapBTN02 = styled.button`
    display:flex; align-items:center; justify-content: flex-start; flex-direction:column; color:#fff; border:none; background-color: #febb00;
     width:152px; height:152px; padding:15px; border-radius:80px;
     
    .imgBox01{ margin-bottom:12px } 

    :hover{  background-color: #e4a802;} 

`;

const SwapLinkbx = styled(FlexDiv)`
  font-size:12px; color:#ababab; font-weight:400; min-height:46px;  width:100%; max-width: 430px;

  a{color:#ababab; margin:0 6px; 
    :hover{ color:#febb00;}
   }
     
`;
const PPClosBTN01 = styled.button`
 width:20px; height:20px; border:0px; outline:none;  color:#fff; background:url(${Xbtn}) left top no-repeat; position:absolute; right:0px;
 transition: 0.5s ease all;
 :hover{ opacity:0.7; transform: rotate(180deg );} 
`;

export default Page02;