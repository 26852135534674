import React, { Component, useContext } from "react";
import styled from "styled-components";

import CurrencyIcon from "./../Assets/images/eth-icon.png";
import { Link } from "react-router-dom";
import { CloneContext } from "../contexts/CloneContext";

const Header = () => {
  const clone = useContext(CloneContext);
  // console.log(clone);
  return (
    <>
      <Mainheadbox className="whitebg">
        <Logo href={clone.cloneData.logoUrl} className="logo-image">
          <img
            className="replacelogo"
            src={
              clone.preview
                ? clone.cloneData.logoImage
                : "./images/darkLogo.png"
            }
            alt=""
          />
        </Logo>
        <HeadCenterboxV2 className="menu">
          <Link to="/page8" className="active">
            Dynamic Swap
          </Link>
          <Link to="/" className="primarybgtextcolor">
            Multi Swap{" "}
          </Link>
          <Link to="/" className="primarybgtextcolor">
            Multi Trade{" "}
          </Link>
          <Link to="/" className="primarybgtextcolor">
            Multi Staking{" "}
          </Link>
          <Link to="/" className="primarybgtextcolor">
            IDO{" "}
          </Link>
          <Link to="/" className="primarybgtextcolor">
            PDO{" "}
          </Link>
          <Link to="/" className="primarybgtextcolor">
            Secure Future
          </Link>
        </HeadCenterboxV2>
        <HeadRightbox>
          <CurrIcon>
            <img src={CurrencyIcon} alt="Currency" />
          </CurrIcon>
          <WalletAddress className="primarybgtextcolor">
            0x291A...H1RS
          </WalletAddress>
          <WalletSignal href="" />
        </HeadRightbox>
      </Mainheadbox>
    </>
  );
};

const Mainheadbox = styled.div`
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  align-items: center;
  justify-content: space-between;
  min-height: 85px;
  background-color: ${(props) => props.theme.headbg01};
  z-index: 100;
  padding: 0 15px;
`;
const Logo = styled.a`
  margin: 0 auto 0 0;
  img {
    max-width: 100%;
    height: auto;
  }
`;

const HeadRightbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CurrIcon = styled.button`
  display: inline-block;
  color: #fff;
  padding: 8px;
  font-weight: 700;
  font-size: 16px;
  border-radius: 10px;
  margin: 0 2px;
`;

const WalletAddress = styled.div`
  color: ${(props) => props.theme.color01};
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
`;

const WalletSignal = styled.a` 
  display:block;
  width:9px; height:9px; background-color: #febb00; border-radius:50%;
  margin: 0 10px 0 10px; 

  &.white{ background-color:#fff;}
  &:hover{ opacity:0.6;}

  
animation: pulse 2s infinite; 
}
@-webkit-keyframes pulse {
0% {
  -webkit-box-shadow: 0 0 0 0 rgba(254,187,0, 0.4);
}
70% {
  -webkit-box-shadow: 0 0 0 10px rgba(254,187,0, 0);
}
100% {
  -webkit-box-shadow: 0 0 0 0 rgba(254,187,0, 0);
}
}
@keyframes pulse {
0% {
  -moz-box-shadow: 0 0 0 0 rgba(254,187,0, 0.4);
  box-shadow: 0 0 0 0 rgba(254,187,0, 0.4);
}
70% {
  -moz-box-shadow: 0 0 0 10px rgba(254,187,0, 0);
  box-shadow: 0 0 0 10px rgba(254,187,0, 0);
}
100% {
  -moz-box-shadow: 0 0 0 0 rgba(254,187,0, 0);
  box-shadow: 0 0 0 0 rgba(254,187,0, 0);
}
}
`;

const HeadCenterbox = styled.div`
  width: 100%;
  max-width: 1120px;
  position: absolute;
  left: 50%;
  top: 19px;
  transform: translateX(-50%);
  padding: 6px 6px 6px 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  span {
    position: absolute;
    left: 0px;
    font-size: 14px;
    font-weight: 600;
    max-width: 100px;
    color: #8e9195;
  }
`;
const HeadCenterboxV2 = styled.div`
  width: 80%;
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  font: normal 12px/20px "Press Start 2P", arial;
  color: #fff;

  a {
    color: #fff;
    padding: 6px 12px 6px 20px;
    position: relative;
    font-size: 12px;
    :hover,
    &.active {
      color: #febb00;
      &:after {
        color: #fff;
      }
    }
    &:after {
      content: "/";
      font: italic 18px/30px arial;
      position: absolute;
      left: 8px;
      top: 0px;
    }
  }
`;

export default Header;
