import React, { Component } from 'react';
import styled from 'styled-components';
import Gs from './../Theme/globalStyles';
import ReactTooltip from 'react-tooltip'; 

class Page04 extends Component {

    constructor() {
        super();
        this.state = {
            popup01: false,
            popup02: false
        }
    }
    render() {
        return (
            <>
                <Gs.Container> 
                        <SS_InfoBoxS03>

                            <SS_InfoTitle02Box>
                                <BxNo>1</BxNo>
                                Adding a token
                                <span>
                                <p>Token additions are immediately availble on bSWAP.</p>
                                <p>Tokens are not displayed and only searchable bby the token smart contract address. After steps 2 and 3 are completed, the token logo will be displayed.</p>
                                <p>In order to add a token to the system, click "<a href="#">Add liquidity</a>" and pair your token (by coping your token smart contract address) with the selected pair.</p>
                            </span>
                            </SS_InfoTitle02Box>

                            <SS_InfoTitle02Box>
                                <BxNo>2</BxNo>
                                Adding a logo
                                <span>
                                <p>bSWAP pulls logos from the trustwallet asset repository on github. </p> 
                                <p><a href="(https://github.com/trustwallet/assets) ">(https://github.com/trustwallet/assets) </a>Add your token icon to that repo and it will appear on the frontend and on info.</p>
                            </span>
                            </SS_InfoTitle02Box>

                            <SS_InfoTitle02Box>
                                <BxNo>3</BxNo>
                                Display token on search 
                                <span>
                                <p>All tokens that complete the second step to add a logo are automatically listed on bSWAP and must likely automatically to be display on all other DEXs as well. </p>
                            </span>
                            </SS_InfoTitle02Box> 
                        </SS_InfoBoxS03>



                </Gs.Container> 
                <ReactTooltip effect="solid" className="myTip" />
            </>
        );
    }
}



// Common Style Div 


const FlexDiv = styled.div`
display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;
const SS_InfoBoxS03 = styled.div` 
    width: 100%;
    padding-left: 40px; 
    text-align: center;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: flex-start; padding-top: 134px; padding-bottom: 134px;
`;
 
const SS_InfoTitle02Box = styled.div` 
    position: relative;
    color: #fff;
    margin: 0 0 45px 0;
    padding-left: 82px;
    font-weight: 700;
    font-size: 18px;
    padding-top: 5px;
    min-height: 62px;
    width: 33.33%;
    text-align: left;
    padding-right: 37px;
    border-right: 1px solid #3a3d46;
    line-height: 22px;

    &:nth-last-child(01){ border-right:0px;}

    & span{ color: #fff;
    display: block;
    font-weight: 400;
    font-size: 14px;
    margin: 4px 0 0 0;}

    & a{ color:#febb00; :hover{ text-decoration:underline;}}

`;

const BxNo = styled.i`
    font: normal 16px/33px 'Press Start 2P', arial;
    color: #fff;
    background-color: #ffbb00;
    position: absolute;
    margin: 0 0 45px 0;
    left: 29px;
    top: 0px;
    padding: 0 6px;
`



export default Page04;