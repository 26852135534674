import React, { Component } from "react";
import styled from "styled-components";
import Gs from "./../Theme/globalStyles";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import TokenPopup from "../Component/popup/tokenPopup";
import SelectTokenPopup from "../Component/popup/selectToken";
import TitleIcon01 from "./../Assets/images/swapTitleIcon-01.png";
import TitleIcon02 from "./../Assets/images/swapTitleIcon-02.png";
import SmbnbIco from "./../Assets/images/smBNB-icon.png";
import UnicornIco from "./../Assets/images/unicornIco.png";
import SSIcon01 from "../Assets/images/swapBICON-01.png";
import SSIcon02 from "../Assets/images/swapBICON-02.png";
import SSIcon03 from "../Assets/images/swapSICON-01.png";
import SSIcon04 from "../Assets/images/swapSICON-02.png";
import SSIcon05 from "../Assets/images/arrowIcon02.png";
import SSIcon06 from "../Assets/images/ethRedioICON.png";

import dexIcon01 from "./../Assets/images/dexIcon-01.png";
import dexIcon02 from "./../Assets/images/dexIcon-02.png";
import dexIcon03 from "./../Assets/images/dexIcon-03.png";
import dexIcon04 from "./../Assets/images/dexIcon-04.png";
import dexIcon05 from "./../Assets/images/dexIcon-05.png";
import dexIcon06 from "./../Assets/images/dexIcon-06.png";
import dexIcon07 from "./../Assets/images/dexIcon-07.png";
import dexIcon08 from "./../Assets/images/dexIcon-08.png";
import dexIcon09 from "./../Assets/images/dexIcon-09.png";
import dexIcon010 from "./../Assets/images/dexIcon-010.png";
import dexIcon011 from "./../Assets/images/dexIcon-011.png";
import dexIcon012 from "./../Assets/images/dexIcon-012.png";
import dexIcon013 from "./../Assets/images/dexIcon-013.png";
import dexIcon014 from "./../Assets/images/dexIcon-014.png";
import dexIcon015 from "./../Assets/images/dexIcon-015.png";
import dexIcon016 from "./../Assets/images/dexIcon-016.png";
import dexIcon017 from "./../Assets/images/dexIcon-017.png";
import dexIcon018 from "./../Assets/images/dexIcon-018.png";
import dexIcon019 from "./../Assets/images/dexIcon-019.png";

class Page08 extends Component {
  constructor() {
    super();
    this.state = {
      popup01: false,
      popup02: false,
    };
  }
  render() {
    return (
      <>
        <Gs.Container>
          <SSswapMBX>
            <SSswapSBX01 className="borRight primarybordercolor">
              <SSBlackBX className="secondarybgcolor primarybordercolor">
                <SSInputMBX className="bordercolor">
                  <SSInputSBX01>
                    {" "}
                    <input
                      type="text"
                      className="secondarybgtextcolor"
                      placeholder="0"
                    />{" "}
                  </SSInputSBX01>
                  <SSInputSBX02>
                    <SSBTN01
                      onClick={() => {
                        this.setState({ popup02: true });
                      }}
                      className="secondarybgtextcolor"
                    >
                      {" "}
                      BEP20 <i className="fas fa-chevron-down"></i>
                    </SSBTN01>
                    <SSBTN01
                      onClick={() => {
                        this.setState({ popup02: true });
                      }}
                      className="v2 secondarybgtextcolor"
                    >
                      {" "}
                      <img src={SSIcon04} alt="" /> bSWAP v2{" "}
                      <i className="fas fa-chevron-down"></i>
                    </SSBTN01>
                  </SSInputSBX02>
                </SSInputMBX>
                <SSTitle01 className="secondarybgtextcolor">
                  {" "}
                  Rate: 1 Bswap v2 = 0.0189881 ETH [$28.48]
                </SSTitle01>
                <SSBTNBar01 className="primarybgcolor">
                  <button className="arrowBTN01 secondarybgcolor"></button>
                </SSBTNBar01>
                <SSInputMBX className="bordercolor">
                  <SSInputSBX01>
                    {" "}
                    <input
                      type="text"
                      className="secondarybgtextcolor"
                      placeholder="0"
                    />{" "}
                  </SSInputSBX01>
                  <SSInputSBX02>
                    <SSBTN01
                      onClick={() => {
                        this.setState({ popup02: true });
                      }}
                      className="secondarybgtextcolor"
                    >
                      {" "}
                      BEP20 <i className="fas fa-chevron-down"></i>
                    </SSBTN01>
                    <SSBTN01
                      onClick={() => {
                        this.setState({ popup02: true });
                      }}
                      className="v2 marFix003 secondarybgtextcolor"
                    >
                      {" "}
                      <img src={SSIcon06} alt="" /> ETH{" "}
                      <i className="fas fa-chevron-down"></i>
                    </SSBTN01>
                  </SSInputSBX02>
                </SSInputMBX>
                <SSTitle01 className="marginFix secondarybgtextcolor">
                  <p>
                    Rate: 1 ETH = 0.0190 bSWAP v2 [$28.51] | Estimated fees: ~ [
                    <span className="redC whitetext">$5.37</span>]
                  </p>
                  <p>
                    bSWAP reward 125.3645 [
                    <span className="greenC whitetext">$5.37]</span>]
                  </p>
                </SSTitle01>
                <SwapBTN01 className="darkGray primarybgcolor primarybgtextcolor clone-font connect-button">
                  <span>Connect Wallet</span>
                </SwapBTN01>
              </SSBlackBX>
            </SSswapSBX01>
            <SSswapSBX02>
              {/* <SSBlackBX>
                                <SSTitle02>Matrix</SSTitle02>
                                <SSTitle03>
                                    Circuit breakers <i className="fas helpIco fa-question-circle" data-tip='Content Coming Soon'></i>
                                    <span>
                                        5% per transaction <br />
                                        10% per day for all transactions
                                    </span>
                                </SSTitle03>

                                <SSTitle03>
                                    Fluctuation fees <i className="fas helpIco fa-question-circle" data-tip='Content Coming Soon'></i>
                                    <span>
                                        From bSWAP to ETH 0.56% <br />
                                        From ETH to bSWAP 0.1%
                                    </span>
                                </SSTitle03>

                                <SSTitle03>
                                    Circuit breakers <i className="fas helpIco fa-question-circle" data-tip='Content Coming Soon'></i>
                                    <span>
                                        bSWAP ROI to date: 5,879.63%
                                    </span>
                                </SSTitle03>

                            </SSBlackBX> */}

              <SSBlackBX className="secondarybgcolor secondarybgtextcolor primarybordercolor">
                <SSTitle02 className="clone-font font-size size32">
                  Add Liquidity
                </SSTitle02>

                <SwapInputMbx className="bordercolor">
                  <SSbx01 className="secondarybgtextcolor">
                    <span>Input </span>
                    <input
                      type="text"
                      className="secondarybgtextcolor"
                      defaultValue="0.0"
                    />
                  </SSbx01>
                  <SSbx02 className="whitetext">
                    <span>Balance: 0.005123 </span>
                    <button
                      className="secondarybgtextcolor"
                      onClick={() => {
                        this.setState({ popup02: true });
                      }}
                    >
                      <div className="maxTitle ">MAX</div>{" "}
                      <div className="imgBox">
                        <img src={SmbnbIco} alt="" />
                      </div>{" "}
                      BNB <i className="fas fa-chevron-down"></i>
                    </button>
                  </SSbx02>
                </SwapInputMbx>
                <SwapTitle02 className="Center">
                  <i className="fas fa-plus secondarybgtextcolor "></i>
                </SwapTitle02>
                <SwapInputMbx className="marginFixer01 bordercolor">
                  <SSbx01 className="secondarybgtextcolor">
                    <span>Input</span>
                    <input
                      type="text"
                      className="secondarybgtextcolor"
                      defaultValue="0.0"
                    />
                  </SSbx01>
                  <SSbx02 className="whitetext">
                    <span>Balance: 0.005123 </span>
                    <button
                      className="OrangeBTN widthFix primarybgcolor primarybgtextcolor clone-font"
                      onClick={() => {
                        this.setState({ popup02: true });
                      }}
                    >
                      Select Token <i className="fas fa-chevron-down"></i>
                    </button>
                  </SSbx02>
                </SwapInputMbx>

                <SwapBTN01 className="primarybgcolor primarybgtextcolor clone-font connect-button">
                  <span>Invalid Pair</span>
                </SwapBTN01>
              </SSBlackBX>
            </SSswapSBX02>

            <SSswapSBX01>
              <SSTitle01 className="marginFix2 secondarybgtextcolor">
                100% gas and fee reimbursement with{" "}
                <a href="#" className="secondarybgtextcolor">
                  bSWAP
                </a>
                <i
                  className="fas helpIco fa-question-circle"
                  data-tip="Content Coming Soon"
                ></i>{" "}
                |{" "}
                <a href="#" className="secondarybgtextcolor">
                  Transaction settings
                </a>{" "}
                |{" "}
                <a href="#" className="secondarybgtextcolor">
                  Analysis
                </a>
              </SSTitle01>
            </SSswapSBX01>
            <SSswapSBX02>
              <SSTitle01 className="marginFix2">
                <a href="#" className="secondarybgtextcolor">
                  {" "}
                  Add liquidity
                </a>{" "}
              </SSTitle01>
            </SSswapSBX02>
          </SSswapMBX>

          <SSIconMBX01>
            <HeadCenterbox>
              <span className="secondarybgtextcolor">Supporting 78 DEXs:</span>
              <Link to="/page2" className="secondarybgcolor">
                <img src={dexIcon01} alt="" />{" "}
              </Link>
              <Link to="/page3" className="secondarybgcolor">
                <img src={dexIcon02} alt="" />{" "}
              </Link>
              <Link to="/page4" className="secondarybgcolor">
                <img src={dexIcon03} alt="" />{" "}
              </Link>
              <Link to="/page5" className="secondarybgcolor">
                <img src={dexIcon04} alt="" />{" "}
              </Link>
              <Link to="/page6" className="secondarybgcolor">
                <img src={dexIcon05} alt="" />{" "}
              </Link>
              <Link href="" className="secondarybgcolor">
                <img src={dexIcon06} alt="" />{" "}
              </Link>
              <Link href="" className="secondarybgcolor">
                <img src={dexIcon07} alt="" />{" "}
              </Link>
              <Link href="" className="secondarybgcolor">
                <img src={dexIcon08} alt="" />{" "}
              </Link>
              <Link href="" className="secondarybgcolor">
                <img src={dexIcon09} alt="" />{" "}
              </Link>
              <Link href="" className="secondarybgcolor">
                <img src={dexIcon010} alt="" />{" "}
              </Link>
              <Link href="" className="secondarybgcolor">
                <img src={dexIcon011} alt="" />{" "}
              </Link>
              <Link href="" className="secondarybgcolor">
                <img src={dexIcon012} alt="" />{" "}
              </Link>
              <Link href="" className="secondarybgcolor">
                <img src={dexIcon013} alt="" />{" "}
              </Link>
              <Link href="" className="secondarybgcolor">
                <img src={dexIcon014} alt="" />{" "}
              </Link>
              <Link href="" className="secondarybgcolor">
                <img src={dexIcon015} alt="" />{" "}
              </Link>
              <Link href="" className="secondarybgcolor">
                <img src={dexIcon016} alt="" />{" "}
              </Link>
              <Link href="" className="secondarybgcolor">
                <img src={dexIcon017} alt="" />{" "}
              </Link>
              <Link href="" className="secondarybgcolor">
                <img src={dexIcon018} alt="" />{" "}
              </Link>
              <Link href="" className="secondarybgcolor">
                <img src={dexIcon019} alt="" />{" "}
              </Link>
            </HeadCenterbox>
          </SSIconMBX01>
        </Gs.Container>
        <SelectTokenPopup
          isOpen={this.state.popup02}
          dismiss={() => {
            this.setState({ popup02: false });
          }}
        />
        <TokenPopup
          isOpen={this.state.popup01}
          dismiss={() => {
            this.setState({ popup01: false });
          }}
        />
        <ReactTooltip effect="solid" className="myTip" />
      </>
    );
  }
}

// Common Style Div

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const SSswapMBX = styled(FlexDiv)`
  width: 100%;
  max-width: 1080px;
  margin: 170px auto 80px auto;
  align-items: flex-start;
`;

const SSswapSBX01 = styled(FlexDiv)`
  width: 65%;
  padding: 0 15px;
  justify-content: flex-start;

  &.borRight {
    border-right: 1px solid #16191e;
  }
`;
const SSswapSBX02 = styled(FlexDiv)`
  width: 35%;
  padding: 0 15px;
  justify-content: flex-start;
`;
const SSBlackBX = styled(FlexDiv)`
  background-color: #16191e;
  border: 2px solid #000;
  padding: 26px;
  border-radius: 10px;
  width: 100%;
  flex-direction: column;
`;
const SSInputMBX = styled(FlexDiv)`
  border: 2px solid #545861;
  border-radius: 10px;
  width: 100%;
  flex-direction: row;
  min-height: 60px;
  justify-content: flex-start;
`;
const SSInputSBX01 = styled(FlexDiv)`
  width: 50%;
  input {
    width: 100%;
    background-color: transparent;
    font-size: 26px;
    font-weight: 700;
    color: #fff;
    padding: 10px 15px;
    outline: none;
    border: none;
  }
`;
const SSInputSBX02 = styled(FlexDiv)`
  width: 50%;
  justify-content: flex-end;
`;
const SSBTN01 = styled.button`
  display: inline-flex;
  font-size: 14px;
  color: #fff;
  margin-left: 12px;
  align-items: center;
  justify-content: center;
  &:last-child {
    margin-right: 10px;
  }
  & img {
    margin-right: 8px;
  }
  & .fas {
    margin-left: 12px;
  }
  &.v2 {
    font-weight: 700;
    min-width: ;
  }

  &.v2.marFix003 {
    margin-left: 68px;
  }
`;
const SSTitle01 = styled(FlexDiv)`
  width: 100%;
  justify-content: flex-start;
  font-size: 11px;
  color: #8e9195;
  margin: 10px 0 10px 0;
  .redC {
    color: #c32b2d;
  }
  .greenC {
    color: #8db610;
  }
  p {
    margin: 0 0 6px 0;
  }

  &.marginFix {
    margin-bottom: 42px;
  }
  &.marginFix2 {
    margin-bottom: 42px;
    color: #ababab;
    font-size: 12px;
    & a {
      font-size: 12px;
    }
  }

  & a {
    color: #8e9195;
    margin: 0 10px;
    :hover {
      text-decoration: underline;
      color: #febb00;
    }
  }

  .helpIco {
    position: relative;
    right: 0px;
    top: -9px;
  }
`;
const SSTitle02 = styled(FlexDiv)`
  font: 300 16px/32px "Press Start 2P", arial;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 36px;
`;
const SSTitle03 = styled(FlexDiv)`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 22px;
  color: #fff;
  justify-content: flex-start;
  border-bottom: 1px solid #292c32;
  span {
    display: block;
    width: 100%;
    font-size: 14px;
    color: #8e9195;
    font-weight: 400;
    margin: 15px 0;
    line-height: 22px;
  }

  &:nth-last-child(01) {
    margin-bottom: 0;
  }

  & .fas {
    font-size: 12px;
  }
`;

const SSBTNBar01 = styled(FlexDiv)`
  width: 100%;
  height: 1px;
  background-color: #282b31;
  margin: 40px 0;
  position: relative;

  button.arrowBTN01 {
    width: 48px;
    height: 34px;
    background-image: url(${SSIcon05});
    left: 50%;
    transform: translateX(-50%);
    top: -18px;
    position: absolute;
    background-position: 50% 50%;
    background-color: #16191e;
    background-repeat: no-repeat;
    :hover {
      opacity: 0.8;
    }
  }
`;

const SSIconMBX01 = styled(FlexDiv)`
  width: 100%;
  position: relative;
  min-height: 42px;
`;

const HeadCenterbox = styled.div`
  width: 100%;
  max-width: 1120px;
  position: absolute;
  left: 50%;
  top: 19px;
  transform: translateX(-50%);
  padding: 6px 6px 6px 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  span {
    position: absolute;
    left: 0px;
    font-size: 14px;
    font-weight: 600;
    max-width: 100px;
    color: #8e9195;
  }

  a {
    display: flex;
    width: 34px;
    height: 34px;
    background-color: #16191e;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    padding: 4px;
    :hover {
      background-color: #000;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
`;

const Title01 = styled.div`
  font: 400 16px/25px "Press Start 2P", arial;
  color: #8e9195;
  margin: 0 0 15px 0;
`;

const TimerBox = styled.div`
  font: 400 50px/60px "Press Start 2P", arial;
  color: #febb00;
  margin: 0 0 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    display: inline-block;
    background-color: #16191e;
    padding: 12px 8px;
    margin: 0 3px;
    border-radius: 10px;
    border: 2px solid #5a5e67;
    text-indent: 6px;
  }
`;

const SwapMbox01 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0 0 0;
`;
const SwapSbox01 = styled(FlexDiv)`
  width: 33.33%;
  justify-content: flex-start;

  &.rightAlign {
    justify-content: flex-end;
  }
`;

const SwapSbox02 = styled(FlexDiv)`
  width: 33.33%;
  padding: 15px;
  flex-direction: column;
`;

const SwapSSbox01 = styled.div`
  width: 100%;
  max-width: 430px;
  background-color: #16191e;
  border: 2px solid #000;
  min-height: 430px;
  padding: 18px;
  border-radius: 10px;

  &.active {
    border-color: #febb00;
    -webkit-box-shadow: 1px 1px 25px 1px rgba(254, 187, 0, 0.4);
    box-shadow: 1px 1px 25px 1px rgba(254, 187, 0, 0.4);
  }
`;

const SwapTitle01 = styled(FlexDiv)`
  font: 300 18px/25px "Press Start 2P", arial;
  color: #fff;
  margin-bottom: 24px;
  position: relative;

  img {
    margin: 0 16px 0 0;
  }
  .helpIco {
    position: absolute;
    right: 0px;
  }
`;

const SwapInputMbx = styled(FlexDiv)`
  border: 2px solid #545861;
  border-radius: 10px;
  min-height: 90px;
  padding: 12px 14px;

  &.marginFixer01 {
    margin-bottom: 22px;
  }
`;
const SSbx01 = styled(FlexDiv)`
  margin: 0 auto 0 0;
  justify-content: flex-start;
  color: #8e9195;
  font-size: 14px;
  width: auto;
  max-width: 40%;
  span {
    width: 100%;
    margin: 0 0 6px 0;
    font-weight: 700;
  }
  input {
    background-color: transparent;
    font-weight: 700;
    font-size: 26px;
    width: 100%;
    height: 25px;
    color: #393d46;
    border: none;
  }
`;
const SSbx02 = styled(FlexDiv)`
  margin: 0 0 0 auto;
  justify-content: flex-end;
  color: #8e9195;
  font-size: 14px;
  width: auto;
  max-width: 55%;
  span {
    width: 100%;
    margin: 0 0 6px 0;
    text-align: right;
    min-height: 20px;
  }

  button {
    font-size: 16px;
    color: #fff;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button.OrangeBTN {
    background-color: #febb00;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    padding: 6px 12px;
    border-radius: 6px;

    .fas {
      font-size: 14px;
    }
    &:hover {
      background-color: #e4a800;
    }

    &.widthFix {
      font-size: 15px;
      padding: 6px 6px;
    }
  }

  .maxTitle {
    background-color: #faecc5;
    margin: 0 0 0 8px;
    font-size: 12px;
    border-radius: 3px;
    padding: 2px 5px;
    color: #000000;
    display: inline-block;
  }
  .imgBox {
    display: inline-block;
    margin: 0 10px;
    padding-top: 6px;
  }
  .fas {
    margin-left: 8px;
  }
`;
const SwapTitle02 = styled(FlexDiv)`
  font: 300 18px/25px "Press Start 2P", arial;
  color: #565a69;
  padding: 18px 0;
  justify-content: flex-start;

  &.Center {
    justify-content: center;
  }
`;
const SwapTitle03 = styled(FlexDiv)`
  font-size: 14px;
  color: #8e9195;
  font-weight: 600;
  min-height: 42px;
  justify-content: flex-start;
  span {
    margin-left: auto;
  }
`;

const SwapTitle04 = styled(FlexDiv)`
  font: 300 18px/40px "Press Start 2P", arial;
  color: #fff;
  margin-bottom: 20px;
  justify-content: flex-start;

  &.smlTitle {
    font: 300 11px/20px "IBM Plex Mono", arial;
    color: #8e9195;
    margin-top: 20px;
  }
`;

const SwapBTN01 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  background-color: #ababab;
  font: 300 16px/32px "Press Start 2P", arial;
  width: 100%;
  padding: 12px 12px;
  border-radius: 10px;

  :hover {
    background-color: #878787;
  }

  &.darkGray {
    background-color: #5d6168;
    :hover {
      background-color: #484c53;
    }
  }
`;

const SwapBTN02 = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  color: #fff;
  border: none;
  background-color: #febb00;
  width: 152px;
  height: 152px;
  padding: 15px;
  border-radius: 80px;

  .imgBox01 {
    margin-bottom: 12px;
  }

  :hover {
    background-color: #e4a802;
  }
`;

const SwapLinkbx = styled(FlexDiv)`
  font-size: 12px;
  color: #ababab;
  font-weight: 400;
  min-height: 46px;
  width: 100%;
  max-width: 430px;

  a {
    color: #ababab;
    margin: 0 6px;
    :hover {
      color: #febb00;
    }
  }
`;

export default Page08;
