import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ETHIcon from "../Assets/images/etherLOGO.png";
import FighterIcon from "../Assets/images/fighter-icon.png";
import BLogo from "../Assets/images/binance-logo.png";
import CloneForm from "./CloneForm";
import { CloneContext } from "../contexts/CloneContext";
const Link02 = () => {
  return (
    <FLink02B className="secondarybgtextcolor">
      <Link to="#" className="secondarybgtextcolor">
        Powered by Atom Foundation:
      </Link>{" "}
      |
      <a
        className="secondarybgtextcolor"
        href="https://www.jointer.io"
        target="_blank"
      >
        Jointer.io
      </a>{" "}
      |{/* <Link to="#" >BSCbonus.com</Link> | */}
      <Link to="#" className="secondarybgtextcolor">
        SmartSwap.exchange
      </Link>{" "}
      |
      <Link to="#" className="secondarybgtextcolor">
        ZERO/1
      </Link>{" "}
      |
      <Link to="#" className="secondarybgtextcolor">
        DegenSwap.io
      </Link>{" "}
      |
      <Link to="#" className="secondarybgtextcolor">
        ElementZero.network
      </Link>{" "}
      |
      <Link to="#" className="secondarybgtextcolor">
        Packey.insure
      </Link>{" "}
      |
      <Link to="#" className="secondarybgtextcolor">
        Mass.investments
      </Link>
    </FLink02B>
  );
};
const Link01 = ({
  closePopUp,
  setCloneData,
  prevCloneData,
  openPopUp,
  clonePopup,
  cloneData,
}) => {
  return (
    <FLink01B className="footer-link">
      {clonePopup && (
        <CloneForm
          close={closePopUp}
          setCloneData={setCloneData}
          prevCloneData={prevCloneData}
        />
      )}
      <Link to="/page7" className="orangeColor secondarybgtextcolor">
        Open Multi Pools on Multi DEXs
      </Link>{" "}
      |
      <Link to="#" className="secondarybgtextcolor">
        Upgrade to v2
      </Link>{" "}
      |
      <Link to="#" className="secondarybgtextcolor">
        LaunchField v1
      </Link>{" "}
      |
      <Link to="#" className="secondarybgtextcolor">
        Voting
      </Link>{" "}
      |
      <Link to="#" className="secondarybgtextcolor">
        Github
      </Link>{" "}
      |
      <Link to="#" className="secondarybgtextcolor">
        Medium
      </Link>{" "}
      |
      <Link to="#" className="secondarybgtextcolor">
        Telegram
      </Link>{" "}
      |
      <Link to="#" className="secondarybgtextcolor">
        Twitter
      </Link>{" "}
      |
      <Link to="#" className="secondarybgtextcolor">
        Discord
      </Link>{" "}
      {!cloneData.isSubdomain && (
        <>
          |
          <a href="#" className="secondarybgtextcolor" onClick={openPopUp}>
            {prevCloneData ? "Continue Clone" : "Free license"}
          </a>
        </>
      )}
    </FLink01B>
  );
};
const FooterFrame = styled.footer`
  background-color: #070707;
  width: 100%;
  height: 100px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  padding: 28px 20px;
  flex-wrap: wrap;
  position: relative;
  @media (min-width: 320px) and (max-width: 500px) {
    flex-wrap: wrap;
    flex-direction: row;
  }
  @media (min-width: 501px) and (max-width: 767px) {
    height: auto;
    padding: 10px 10px 30px 10px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    height: auto;
    padding: 10px 10px 30px 10px;
  }
`;
const JRLogo = styled.div`
  margin-right: auto;
  min-width: 110px;
  @media (min-width: 320px) and (max-width: 500px) {
    margin: 0 auto 20px auto;
  }
`;
const FooterCbox = styled.div`
  text-align: center;
  margin: 0 auto;
`;
const FLink01B = styled.div`
  font: normal 15px/16px arial;
  color: #424242;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    font: normal 10px/16px "Press Start 2P", arial;
    margin: 0 16px;
    &:hover {
      color: #f3b92e;
    }
    &.orangeColor {
      color: #febb00;
    }
  }
`;
const FLink02B = styled.div`
  font: normal 15px/16px arial;
  color: #2a2a2a;
  padding-bottom: 8px;
  a {
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    font: normal 12px/16px "IBM Plex Mono", arial;
    margin: 0 6px;
    &:hover {
      color: #f3b92e;
    }
  }
`;
const BinanceLogo = styled.div`
  margin-left: auto;
  @media (min-width: 320px) and (max-width: 500px) {
    margin: 0 auto 20px auto;
  }
`;
const BinanceLogoLink = styled.a`
  &:hover {
    opacity: 0.7;
  }
`;
const ETHLogoLink = styled.a`
  margin: 0 12px 0 0;
  position: relative;
  &:hover {
    opacity: 0.7;
  }
  &:after {
    content: "";
    width: 1px;
    display: block;
    height: 34px;
    background-color: #2a2a2a;
    border: none;
    position: absolute;
    right: -4px;
    top: -20px;
  }
`;
const FooterBody = styled.div`
  display: block;
  margin: 0;
  width: 100%;
`;
const Footer = () => {
  const cloneData = useContext(CloneContext);
  const [clonePopup, setClonePopUp] = useState(false);
  const [prevCloneData, setPrevCloneData] = useState();
  const setCloneData = (data) => {
    cloneData.updateCloneData(data, true);
    setPrevCloneData(data);
    setClonePopUp(false);
    // console.log(data);
    document.getElementsByTagName("body")[0].style.fontFamily =
      data.fontStyle === "Default" ? "Press Start 2P" : `${data.fontStyle}`;
  };

  const closePopUp = () => {
    cloneData.updateCloneData(
      {
        bgimage: "",
        logoImage: "",
        subDomain: "",
        primaryColor: "",
        secondaryColor: "",
        fontStyle: "Default",
        backgroundOverLay: "60",
        logoUrl: "",
        swapButtonColor: "",
      },
      false
    );
    setPrevCloneData();
    setClonePopUp(false);
    document.getElementsByTagName("body")[0].style.fontFamily = "";
    const r = document.querySelector(":root");
    r.style.removeProperty("--primarycolor");
    r.style.removeProperty("--secondarycolor");
    r.style.removeProperty("--swap-btn-color");
    document.getElementsByTagName("body")[0].classList.remove("subdomain");
  };
  return (
    <FooterBody>
      <FooterFrame className="secondarybgcolor">
        <JRLogo>
          <img
            style={{ position: "absolute", left: 10, top: -60 }}
            src={FighterIcon}
            alt="Fighter"
          />
        </JRLogo>
        <FooterCbox>
          <Link01
            closePopUp={closePopUp}
            setCloneData={setCloneData}
            prevCloneData={prevCloneData}
            openPopUp={() => setClonePopUp(true)}
            clonePopup={clonePopup}
            cloneData={cloneData}
          />
          <Link02 />
        </FooterCbox>
        <BinanceLogo>
          <ETHLogoLink href="#" target="_blank">
            <img src={ETHIcon} />{" "}
          </ETHLogoLink>
          <BinanceLogoLink href="https://www.binance.org/en" target="_blank">
            <img src={BLogo} />{" "}
          </BinanceLogoLink>
        </BinanceLogo>
      </FooterFrame>
    </FooterBody>
  );
};
export default Footer;
