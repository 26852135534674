import React, { Component } from 'react';
import styled from 'styled-components';
import Gs from './../Theme/globalStyles';
import ReactTooltip from 'react-tooltip';
import TokenPopup from '../Component/popup/tokenPopup'
import SelectTokenPopup from '../Component/popup/selectToken'
import TitleIcon01 from './../Assets/images/swapTitleIcon-01.png'
import TitleIcon02 from './../Assets/images/swapTitleIcon-02.png'
import TitleIcon03 from './../Assets/images/swapTitleIcon-03.png'
import SmbnbIco from './../Assets/images/smBNB-icon.png'
import UnicornIco from './../Assets/images/unicornIco.png'
import Xbtn from '../Assets/images/closeBTN.png'
import BswapICO from '../Assets/images/bSwap-Icon.png'
import ddIcon01 from '../Assets/images/cakeSmallIcon.png'
import ddIcon02 from '../Assets/images/bnbSmallIcon.png'
import ddIcon03 from '../Assets/images/bnbSmallIcon02.png'
class Page05 extends Component {
    constructor() {
        super();
        this.state = {
            popup01: false,
            popup02: false,
            viewType: false
        }
    }
    setShow (a){
        this.setState({viewType:a})
    }
    render() {
        const { viewType } = this.state;
        
        return (
            <>
                <Gs.Container>
                    <SwapMbox01>
                        <SwapSbox01>
                            <SwapSSbox01 className="OrangBX">
                                <SwapTitle01>
                                    <img src={TitleIcon03} alt="" /> Migrate Liquidity</SwapTitle01>
                                <SwapInputMbx className="WhiteBack">
                                    <MLSubBox>
                                        Non-hardware wallet
                                        <span>(Trust Wallet, MetaMask, etc.)</span>
                                        <button><i className="fas fa-chevron-down"></i></button>
                                    </MLSubBox>
                                </SwapInputMbx>
                                <Spacer />
                                <SwapTitle05>Your current LP liquidity</SwapTitle05>
                                <SwapInputMiniBx>
                                    <SwapMiniBTN>
                                        <span> <IconImgbx>  <img src={ddIcon01} alt="" /></IconImgbx> <IconImgbx> <img src={ddIcon02} alt="" />  </IconImgbx> CAKE-BNB LP</span>
                                        <span>0.135786 <i className="fas fa-chevron-down"></i></span>
                                    </SwapMiniBTN>
                                </SwapInputMiniBx>
                                <Spacer />
                                <SwapTitle05>Amount of LP to migrate</SwapTitle05>
                                <SwapInputMiniBx>
                                    <SwapMiniBTN>
                                        <span>0.1357862937128</span>
                                        <span> <MaxTitle>MAX</MaxTitle></span>
                                    </SwapMiniBTN>
                                </SwapInputMiniBx>
                                <SwapDetailBX>
                                    <span>CAKE-BNB LP   <span>0.1357862937128</span>  </span>
                                    <span>Market Price   <span>1.2536%</span>  </span>
                                    <span>Relayer Fee (0.20%)  <span>0.00027156</span>  </span>
                                    <span>Swap Fee (0.30%)   <span>0.00040734</span>  </span>
                                    <span>Expiration  <span>0.13510735855</span>  </span>
                                </SwapDetailBX>
                                <SwapTitle02>  </SwapTitle02>
                                <SwapBTN01 className='blackBack'>Enter an Amount</SwapBTN01>
                            </SwapSSbox01>
                            <SwapLinkbx>
                                <a href=''>Add liquidity</a> |
                                <a href=''>Migrate liquidity</a>
                            </SwapLinkbx>
                        </SwapSbox01>
                        <SwapSbox01 className="rightAlign" style={{ marginLeft: '30px' }}>
                            <SwapSSbox01 className='BlackBX'>
                                <SwapTitle01>  <img src={TitleIcon02} alt="" />  Swap </SwapTitle01>
                                <AlertTitle01>This feature is in beta and contracts are unaudited. Read the <a href='#'>contract</a> and use it at your own risk.</AlertTitle01>
                                <SwapInputMbx className='WhiteBack'>
                                    <SSbx01>
                                        <span>Token to sell </span>
                                        <input type='text' defaultValue='0.0' />
                                    </SSbx01>
                                    <SSbx02>
                                        <span>  </span>
                                        <button>
                                            <div className="imgBox"><img src={SmbnbIco} alt="" /></div> BNB <i className="fas fa-chevron-down"></i>
                                        </button>
                                    </SSbx02>
                                </SwapInputMbx>
                                <SwapTitle02 >
                                    <i className="fas fa-arrow-down"></i>
                                </SwapTitle02>
                                <SwapInputMbx className='WhiteBack'>
                                    <SSbx01>
                                        <span>Token to buy </span>
                                        <input type='text' defaultValue='0.0' />
                                    </SSbx01>
                                    <SSbx02>
                                        <span>  </span>
                                        <button className="OrangeBTN" onClick={() => { this.setState({ popup02: true }) }}>
                                            Select Token <i className="fas fa-chevron-down"></i>
                                        </button>
                                    </SSbx02>
                                </SwapInputMbx>
                                <Spacer />
                                <SwapTitle05>Minimum price </SwapTitle05>
                                <SwapMiniText>
                                    <span>(BNB/token)</span>
                                </SwapMiniText>
                                <SwapDetailBX>
                                    <span>Market Price<span>0.00157356 ETH / USDC</span>  </span>
                                    <span>Relayer Fee (0.20%)  <span>0.046282 USDC</span>  </span>
                                    <span>Swap Fee (0.30%)  <span>0.069284 USDC</span>  </span>
                                    <span>Expiration   <span> </span>  </span>
                                </SwapDetailBX>
                                <SwapBTN01 className='orangeBack'>Limit Order</SwapBTN01>
                            </SwapSSbox01>
                            <SwapLinkbx>
                                <a href=''>Market order</a> |
                                <a href=''>Limit order</a> |
                                <a href=''>List new token</a>
                            </SwapLinkbx>
                        </SwapSbox01>
                    </SwapMbox01>
                </Gs.Container>
                <Gs.Container>
                    <LedgerTitle01>
                        <button>Ledger <i className="fas fa-caret-up"></i></button>
                        <LedgerTiFilter>
                            <button className={`${viewType ? '' : 'active'}`} onClick={() => this.setShow(false)}><i className="fas fa-square"></i></button>
                            <button className={`${viewType ? 'active' : ''}`} onClick={() => this.setShow(true)}><i className="fas fa-th-list"></i></button>
                        </LedgerTiFilter>
                    </LedgerTitle01>
                    <LedgerGridViewBX style={viewType ? { display: 'none' } : {}}>
                        <LedgerLinkBX>
                            <button>All</button>
                            <button>Completed</button>
                            <button>Pending</button>
                        </LedgerLinkBX>
                        <LedgerDetailBX>
                            <LedgerDetSbox>
                                <LedgerDetSbox02>
                                    <LedgerTitle02>Sent</LedgerTitle02>
                                    <LedgerTitle03>2 bSWAP v2
                                            <span>February 2, 2019, 9:21am PST</span>
                                    </LedgerTitle03>
                                    <LedgerTitle04>Transaction Submitted
                                            <span>X0456c19d5A61AeA886E6D657EsEF8849565</span>
                                        <i class="fas fa-check-circle"></i>
                                        <button>View transaction</button>
                                    </LedgerTitle04>
                                </LedgerDetSbox02>

                            </LedgerDetSbox>
                            <LedgerDetSbox>
                                <LedgerDetSbox02>
                                    <LedgerTitle02>Received <span>(100%)</span></LedgerTitle02>
                                    <LedgerTitle03>1 BNB
                                            <span>February 2, 2019, 9:21am PST</span>
                                    </LedgerTitle03>
                                    <LedgerTitle04>Transaction Submitted
                                            <span>X0456c19d5A61AeA886E6D657EsEF8849565</span>
                                        <i class="fas fa-check-circle"></i>
                                        <button>View transaction</button>
                                    </LedgerTitle04>
                                </LedgerDetSbox02>

                                <LedgerDetSbox02>
                                    <LedgerTitle02>Pending <span>(62.53%)</span></LedgerTitle02>
                                    <LedgerTitle03>1 BNB
                                            <span>February 2, 2019, 9:21am PST</span>
                                    </LedgerTitle03>
                                    <LedgerTitle04 className='Small'>
                                        Pending
                                <PendingTag>
                                            <i class="fas fa-cog"></i>
                                Wait until a match is found or cancel and redeem the 1 BNB pending to your wallet
                                </PendingTag>
                                    </LedgerTitle04>
                                </LedgerDetSbox02>
                            </LedgerDetSbox> 
                        </LedgerDetailBX> 
                    </LedgerGridViewBX>
 
                    <LedgerListViewBX  style={viewType ? {} : { display: 'none' }}>
                                
                    <div class="wb-Table04">
                    <table width="100%" border="0" cellspacing="0" cellpadding="16">
                        <tbody>
                                <tr>
                                        <td colspan="3" align="center" valign="middle" class="wbt4Title01">Sent</td>
                                        <td colspan="4" align="center" valign="middle" class="wbt4Title01">Receive</td>
                                </tr>
                                <tr>
                                        <th align="left" valign="middle" scope="col">Time</th>
                                        <th align="left" valign="middle" scope="col">Asset</th>
                                        <th align="left" valign="middle" scope="col">Proof</th>
                                        <th align="left" class="tdBRleft" valign="middle" scope="col">Time</th>
                                        <th align="left" valign="middle" scope="col">Asset</th>
                                        <th align="left" valign="middle" scope="col">Percentage</th>
                                        <th align="left" valign="middle" scope="col">Proof</th>
                                </tr>
                                <tr>
                                        <td align="left" valign="middle">February 2, 2019, <br />
                                        9:21am PST</td>
                                        <td align="left" valign="middle">2 bSWAP V2</td>
                                        <td align="left" valign="middle"><a href='#'>View transaction</a></td>
                                        <td align="left" valign="middle" class="tdBRleft">February 2, 2019, <br />
                                        9:21am PST</td>
                                        <td align="left" valign="middle">1 BNB</td>
                                        <td align="left" valign="middle">100%</td>
                                        <td align="left" valign="middle"><a href='#'>View transaction</a></td>
                                </tr>
                                <tr>
                                        <td align="left" valign="middle">February 2, 2019, <br />
                                                9:21am PST</td>
                                        <td align="left" valign="middle">1 BNB</td>
                                        <td align="left" valign="middle"><a href='#'>View transaction</a></td>
                                        <td align="left" valign="middle" class="tdBRleft">February 2, 2019, <br />
                                                9:21am PST</td>
                                        <td align="left" valign="middle">1 BNB</td>
                                        <td align="left" valign="middle">37.47%</td>
                                        <td align="left" valign="middle"><a href='#'>View transaction <i class="fas fa-sort-up"></i></a></td>
                                </tr>
                                <tr>
                                        <td colspan="3" rowspan="3" align="left" valign="middle">&nbsp;</td>
                                        <td align="left" valign="middle" class="tdBRleft">&nbsp;</td>
                                        <td align="left" valign="middle" class="grayText">1 BNB</td>
                                        <td align="left" valign="middle" class="grayText">100%</td>
                                        <td align="left" valign="middle"><a href='#'>View transaction</a></td>
                                </tr>
                                <tr>
                                        <td align="left" valign="middle" class="tdBRleft">&nbsp;</td>
                                        <td align="left" valign="middle" class="grayText">1 BNB</td>
                                        <td align="left" valign="middle" class="grayText">100%</td>
                                        <td align="left" valign="middle"><a href='#'>View transaction</a></td>
                                </tr>
                                <tr>
                                        <td align="left" valign="middle" class="tdBRleft">&nbsp;</td>
                                        <td align="left" valign="middle" class="grayText">1 BNB</td>
                                        <td align="left" valign="middle" class="grayText">100%</td>
                                        <td align="left" valign="middle" class="grayText">Pending <a href='#' class="redColor">[Cancel and redeem to wallet]</a></td>
                                </tr>
                                <tr>
                                		<td align="left" valign="middle">February 2, 2019, <br />
                                				9:21am PST</td>
                                		<td align="left" valign="middle">2 bSWAP V2</td>
                                		<td align="left" valign="middle"><a href='#'>View transaction</a></td>
                                		<td align="left" valign="middle" class="tdBRleft">February 2, 2019, <br />
                                				9:21am PST</td>
                                		<td align="left" valign="middle">1 BNB</td>
                                		<td align="left" valign="middle">100%</td>
                                		<td align="left" valign="middle"><a href='#'>View transaction</a></td>
                           		</tr>
                        </tbody>
                </table>
        </div>
                    </LedgerListViewBX>
                






                </Gs.Container>
                <SelectTokenPopup isOpen={this.state.popup02} dismiss={() => { this.setState({ popup02: false }) }} />
                <TokenPopup isOpen={this.state.popup01} dismiss={() => { this.setState({ popup01: false }) }} />
                <ReactTooltip effect="solid" className="myTip" />
            </>
        );
    }
}
// Common Style Div 
const FlexDiv = styled.div`
display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;
const AlertTitle01 = styled.div`
    font-size:11px; color:#c32b2d; margin: 0 6px 15px 6px; line-height:18px; 
    & span{ font-size:14px; font-weight:700; margin-bottom:10px; }
    & a{text-decoration:underline; color:#c32b2d;  :hover{color:#fff;}}
`;
const Title01 = styled.div`
  font:400 16px/25px 'Press Start 2P', arial; color:#8e9195; margin: 0 0 15px 0;
`;
const TimerBox = styled.div`
  font:400 50px/60px 'Press Start 2P', arial; color:#febb00; margin: 0 0 15px 0;
  display:flex; align-items: center; justify-content:flex-start;
  span{display: inline-block; background-color: #16191e;  padding: 12px 8px; margin: 0 3px; border-radius: 10px; border: 2px solid #5a5e67; text-indent: 6px;}
`;
const SwapMbox01 = styled.div`
   display: flex; align-items: center; justify-content:center; margin:60px 0 0 0;
`;
const SwapSbox01 = styled(FlexDiv)`
   width: 33.33%; justify-content:flex-start ;
  &.rightAlign{ justify-content:flex-end ;  margin-bottom:auto; }
`;
const SwapSbox02 = styled(FlexDiv)`
   width: 33.33%; padding:15px; flex-direction: column;
`;
const SwapSSbox01 = styled.div`
   width:100%; max-width:430px; background-color: #16191e; border: 2px solid #000; min-height: 430px; padding:18px; border-radius: 10px;
  &.active{ border-color:#febb00; -webkit-box-shadow: 1px 1px 25px 1px rgba(254,187,0,0.4);
box-shadow: 1px 1px 25px 1px rgba(254,187,0,0.4);} 
    &.OrangBX{ 
        background-color:#febb00; border-color:#fff; -webkit-box-shadow: 1px 1px 25px 1px rgba(254,187,0,0.4); box-shadow: 1px 1px 25px 1px rgba(254,187,0,0.4);
    } 
    &.BlackBX{ 
        background-color:#000000; border-color:#fff; -webkit-box-shadow: 1px 1px 25px 1px rgba(0,0,0,0.4); box-shadow: 1px 1px 25px 1px rgba(0,0,0,0.4);
    }
`;
const SwapTitle01 = styled(FlexDiv)`
  font:300 18px/25px 'Press Start 2P', arial; color:#fff; margin-bottom:24px;  position:relative;
  img{margin:0 16px 0 0; } 
  .helpIco{ position:absolute; right:0px;}
`;
const SwapInputMbx = styled(FlexDiv)`
    border:2px solid #545861; border-radius: 10px; min-height:90px; padding:12px 14px;  
    &.WhiteBack{ border-color:#f0f0f0; background-color:#fff;} 
`;
const SwapInputMiniBx = styled(SwapInputMbx)`
border-color:#f0f0f0; background-color:#fff; min-height:46px;
`
const SwapMiniText = styled(SwapInputMiniBx)`
border-color:#f0f0f0; background-color:#fff; min-height:46px; justify-content:flex-start; color:#000; margin-bottom:15px;
`
const MLSubBox = styled(FlexDiv)` 
   width:100%; position:relative; font-size:14px; color:#000; font-weight:700; justify-content:flex-start; 
    span{font-size:12px;  font-weight:400; } 
    button{ display:inline-block; padding:6px; position:absolute; right:6px; :hover{opacity:0.7;}  } 
`;
const ConnectWallText = styled(FlexDiv)`
    width:100%; max-width:260px; font-size:14px; color:#565a69; text-align:center; font-weight:400; 
`;
const SSLinkbox01 = styled(FlexDiv)`
    width:100%;  font-size:12px; color:#565a69; text-align:center; font-weight:400; padding:15px 0; 
    & a{ color:#febb00; margin-left:4px;  :hover{ text-decoration:underline;} }
`;
const SSbx01 = styled(FlexDiv)`
    margin:0 auto 0 0; justify-content: flex-start; color:#8e9195; font-size:14px; width: auto; max-width:40%; 
    span{ width:100%; margin:0 0 6px 0; font-weight:700;
        ${SwapInputMbx}.WhiteBack & {  color:#565a69; }
     }
    input{ background-color:transparent; font-weight:700; font-size:26px; width:100%; height:25px; color:#393d46; border:none;
        ${SwapInputMbx}.WhiteBack & {  color:#dfe0e3; }
     } 
`;
const SSbx02 = styled(FlexDiv)`
    margin:0 0 0 auto; justify-content: flex-end; color:#8e9195; font-size:14px; width: auto; max-width:55%; 
    span{ width:100%; margin:0 0 6px 0; text-align:right; min-height:20px;   }  
    button{
        font-size:16px; color:#fff;  font-weight: 700; display:flex; align-items: center; justify-content:space-between;
        ${SwapInputMbx}.WhiteBack & {
            color:#000;
        }
    }
    button.OrangeBTN{ background-color:#febb00; font-size:16px; color:#fff; font-weight:500; padding:6px 12px; border-radius:6px;
    .fas{font-size:14px;}
    ${SwapInputMbx}.WhiteBack & { 
        color:#fff;
    }
    &:hover{ background-color:#e4a800;}  
    }
    .maxTitle{ background-color:#faecc5;  margin:0 0 0 8px; font-size:12px; border-radius:3px; padding:2px 5px; color:#000000; display:inline-block; } 
    .imgBox{ display:inline-block; margin:0 10px; padding-top:6px; }
    .fas { margin-left:8px;}
`;
const SwapTitle02 = styled(FlexDiv)`
  font:300 18px/25px 'Press Start 2P', arial; color:#565a69;  padding:18px 0; justify-content:flex-start;
    &.Center{ justify-content:center;}
`;
const SwapTitle03 = styled(FlexDiv)`
  font-size:14px; color:#8e9195; font-weight:600; min-height:42px;   justify-content:flex-start;
    span{ margin-left:auto;}
    &.smallText{ color:#565a69; font-size:12px;}
`;
const SwapTitle04 = styled(FlexDiv)`
    font:300 18px/40px 'Press Start 2P', arial; color:#fff; margin-bottom:20px;    justify-content:flex-start;  
    &.smlTitle{  font:300 11px/20px 'IBM Plex Mono', arial; color:#8e9195; margin-top:20px;}
`;
const SwapTitle05 = styled(FlexDiv)`
 font-size:18px; font-weight:700; color:#fff; margin:25px 0 15px 0;    justify-content:flex-start;  
 & .helpIco{ font-size:14px; margin-left:auto; right:3px; top:0px;}
`;
const SwapBTN01 = styled.button`
    display:flex; align-items:center; justify-content: center; color:#fff; border:none; background-color: #ababab;
    font:300 16px/32px 'Press Start 2P', arial; width:100%; padding:12px 12px; border-radius: 10px;
    :hover{  background-color: #878787;}
    &.darkGray{ background-color:#5d6168;
        :hover{  background-color: #484c53;} }
    &.orangeBack{ background-color:#febb00;
    :hover{  background-color: #ecae00; } 
    }
    &.blackBack{ background-color:#000000;
    :hover{  background-color: #302300; } 
    }
`;
const SwapBTN02 = styled.button`
    display:flex; align-items:center; justify-content: flex-start; flex-direction:column; color:#fff; border:none; background-color: #febb00;
     width:152px; height:152px; padding:15px; border-radius:80px;
    .imgBox01{ margin-bottom:12px }  
    :hover{  background-color: #e4a802;} 
`;
const SwapLinkbx = styled(FlexDiv)`
  font-size:12px; color:#ababab; font-weight:400; min-height:46px;  width:100%; max-width: 430px;
  a{color:#ababab; margin:0 6px; 
    :hover{ color:#febb00;}
   }  
`;
const InitialPriceBox = styled(FlexDiv)`
    width:33.33%; flex-direction:column; font-size:12px; font-weight:700; color:#565a69;
`;
const PPClosBTN01 = styled.button`
 width:20px; height:20px; border:0px; outline:none;  color:#fff; background:url(${Xbtn}) left top no-repeat; position:absolute; right:0px;
 transition: 0.5s ease all;
 :hover{ opacity:0.7; transform: rotate(180deg );} 
`;
const Spacer = styled.div`
    width:100%; height:1px; background-color:#e4a700; margin:13px 0;
    ${SwapSSbox01}.BlackBX & { 
        background-color:#242424;
    }
`
const SwapMiniBTN = styled.button` 
    width:100%; display: flex; align-items: center; justify-content:space-between;  font-weight:500;
    & span{display:flex; align-items:center;  
        & .fas{ margin-left:6px;}
    } 
`
const MaxTitle = styled.div` 
background-color:#faecc5;  margin:0 0 0 8px; font-weight:700; font-size:12px; border-radius:3px; padding:2px 5px; color:#000000; display:inline-block; 
`
const SwapDetailBX = styled.div`
    font-size:12px; color:#fff; margin:20px 0;
    & span{ width:100%; display:flex; align-items:center; justify-content:space-between; padding:6px 0; 
    span{ width:auto; padding:0;} 
    } 
    ${SwapSSbox01}.BlackBX & { 
        color:#ababab;
    } 
`
const IconImgbx = styled(FlexDiv)`
width:20px; height:20px; margin:0 8px 0 0; 
 & img{ max-width:100%; height:auto; } 
`
const LedgerTitle01 = styled(FlexDiv)`
    width:100%; justify-content:space-between; font-size:24px; color:#fff; font-weight:700; margin-top:50px;
    & button { color:#fff; font-weight:700;
        & .fas{ margin-left:20px;} 
    }
`
const LedgerTiFilter = styled(FlexDiv)`
    justify-content:flex-end; padding:0;
    & button { color:#8e9195; font-size:16px; display:block; max-width:22px; padding:0; margin:0 0 0 8px; 
        :hover{ color:#febb00; }
        &.active{color:#febb00;}
    }
    
`
const LedgerLinkBX = styled(FlexDiv)`
width:100%; justify-content: flex-start; margin:40px 0 60px 0;

 & button{  font-size:16px; font-weight:700; color:#fff; margin-right:16px; 
            :hover{ color:#febb00;}
            &.active{ color:#febb00;} 
         } 
`

const LedgerDetailBX = styled(FlexDiv)`
    justify-content: flex-start; align-items:flex-start;
`
const LedgerDetSbox = styled(FlexDiv)`
    width:50%; padding:0 15px 0 0; justify-content: flex-start;
    
    &:nth-last-child(01){ padding:0 0 0 15px;} 
`
const LedgerGridViewBX = styled.div`

`
const LedgerListViewBX = styled.div` 
         padding-top:20px; 
`


const LedgerDetSbox02 = styled(FlexDiv)`
  width:100%; flex-wrap:wrap;  justify-content: flex-start; padding: 30px 0; border-bottom:1px solid #383c45;
      
`
const LedgerTitle02 = styled(LedgerTitle01)`
    margin:0 0 35px 0;  justify-content:flex-start;
    & span{ font-weight:400; font-size:14px; margin-left:10px; } 
`
const LedgerTitle03 = styled.div`
    margin:0 0 20px 0;  font-size:18px; line-height:26px; font-weight:700;  width:100%;
    & span{ font-weight:400; font-size:12px; display:block;} 
`
const LedgerTitle04 = styled.div`
    padding:0 0 0 27px;  font-size:14px; line-height:26px; font-weight:700;  width:100%; position:relative;
    & span{ font-weight:400; font-size:12px; display:block;}  
    & .fas{position:absolute; left:0px; top:6px; }
    & button{ color:#4848ff; font-size:12px; font-weight:400; padding:0;
        :hover{ text-decoration:underline;}
     }

     &.Small{ padding:0; } 
`
const PendingTag = styled(FlexDiv)`
    color:#c32b2d; font-size:11px; justify-content:space-between; margin:10px 0 0 0;
    & .fas{position:relative; left:auto; top:auto; }
`

export default Page05;