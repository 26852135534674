import React, { useState } from "react";

const CloneContext = React.createContext();

const CloneContextProvider = ({ children }) => {
  const [isSubdomain, setIsSubdomain] = useState(false);
  const [preview, setPreview] = useState(false);
  const [cloneData, setCloneData] = useState({
    bgimage: "",
    logoImage: "",
    subDomain: "",
    primaryColor: "",
    secondaryColor: "",
    backgroundOverLay: "60",
    fontStyle: "Default",
    logoUrl: "",
    swapButtonColor: "",
  });

  const updateCloneData = (data, type) => {
    setCloneData({
      bgimage: data.bgimage,
      logoImage: data.logoImage,
      subDomain: data.subDomain,
      primaryColor: data.primaryColor,
      secondaryColor: data.secondaryColor,
      backgroundOverLay: data.backgroundOverLay,
      fontStyle: data.fontStyle,
      logoUrl: data.logoUrl,
      swapButtonColor: data.swapButtonColor,
    });
    setPreview(type);
  };
  const updateSubDomain = (type) => {
    setIsSubdomain(type);
  };

  return (
    <CloneContext.Provider
      value={{
        preview,
        cloneData,
        updateCloneData,
        updateSubDomain,
        isSubdomain,
      }}
    >
      {children}
    </CloneContext.Provider>
  );
};

export { CloneContext, CloneContextProvider };
