import React, { Component } from 'react';
import styled from 'styled-components';
import Gs from './../Theme/globalStyles';
import ReactTooltip from 'react-tooltip';
import TokenPopup from '../Component/popup/tokenPopup'
import SelectTokenPopup from '../Component/popup/selectToken'
import TitleIcon01 from './../Assets/images/swapTitleIcon-01.png'
import TitleIcon02 from './../Assets/images/swapTitleIcon-02.png'
import TitleIcon03 from './../Assets/images/swapTitleIcon-03.png'
import SmbnbIco from './../Assets/images/smBNB-icon.png'
import UnicornIco from './../Assets/images/unicornIco.png'
import Xbtn from '../Assets/images/closeBTN.png'
import BswapICO from '../Assets/images/bSwap-Icon.png'
import ddIcon01 from '../Assets/images/cakeSmallIcon.png'
import ddIcon02 from '../Assets/images/bnbSmallIcon.png'
import ddIcon03 from '../Assets/images/bnbSmallIcon02.png'
import SSIcon01 from '../Assets/images/swapBICON-01.png'
import SSIcon02 from '../Assets/images/swapBICON-02.png'
import SSIcon03 from '../Assets/images/swapSICON-01.png'
import SSIcon04 from '../Assets/images/swapSICON-02.png'
import ArrowICON from '../Assets/images/arrowIcon.png'
class Page06 extends Component {
    constructor() {
        super();
        this.state = {
            popup01: false,
            popup02: false,
            viewType: false
        }
    }
    setShow(a) {
        this.setState({ viewType: a })
    }
    render() {
        const { viewType } = this.state;
        return (
            <>
                <Gs.Container>
                    <SwapMbox01>
                        <SwappingBOXM>
                            <SwapingMbx>
                                <SwappingLink01>
                                    <button>
                                    </button>
                                </SwappingLink01>
                                <SwapInputBX>
                                    <i className="ImgBX"> <img src={SSIcon03} /> </i>
                                    <input type='text' defaultValue='Send' />
                                </SwapInputBX>
                                <BswapBTN01>
                                    <img src={SSIcon01} />
                                </BswapBTN01>
                                <Bswaptitle01>bSWAP v1</Bswaptitle01>
                            </SwapingMbx>
                            <SwapingMbx>
                                <SwapInputBX>
                                    <i className="ImgBX"> <img src={SSIcon04} /> </i>
                                    <input type='text' defaultValue='Receive' />
                                </SwapInputBX>
                                <BswapBTN01>
                                    <img src={SSIcon02} />
                                </BswapBTN01>
                                <Bswaptitle01>bSWAP v2</Bswaptitle01>
                            </SwapingMbx>
                            <SwapBTN01 className='darkGray'>Connect Wallet</SwapBTN01>
                            <SwapText01>1:1 ratio swap with zero fees</SwapText01>
                        </SwappingBOXM>
                    </SwapMbox01>
                </Gs.Container>
                <Gs.Container>
                    <LedgerTitle01>
                        <button>Ledger <i className="fas fa-caret-up"></i></button>
                        <LedgerTiFilter>
                            <button className={`${viewType ? '' : 'active'}`} onClick={() => this.setShow(false)}><i className="fas fa-square"></i></button>
                            <button className={`${viewType ? 'active' : ''}`} onClick={() => this.setShow(true)}><i className="fas fa-th-list"></i></button>
                        </LedgerTiFilter>
                    </LedgerTitle01>
                    <LedgerLinkBX>
                            <button>Completed</button>
                            <button>Staking</button>
                        </LedgerLinkBX>
                    <LedgerGridViewBX style={viewType ? { display: 'none' } : {}}>
                        
                        <LedgerDetailBX>
                            <LedgerDetSbox>
                                <LedgerDetSbox02>
                                    <LedgerTitle02>Sent</LedgerTitle02>
                                    <LedgerTitle03>2 bSWAP v2
                                            <span>February 2, 2019, 9:21am PST</span>
                                    </LedgerTitle03>
                                    <LedgerTitle04>Transaction Submitted
                                            <span>X0456c19d5A61AeA886E6D657EsEF8849565</span>
                                        <i class="fas fa-check-circle"></i>
                                        <button>View transaction</button>
                                    </LedgerTitle04>
                                </LedgerDetSbox02>
                            </LedgerDetSbox>
                            <LedgerDetSbox>
                                <LedgerDetSbox02>
                                    <LedgerTitle02>Received <span>(100%)</span></LedgerTitle02>
                                    <LedgerTitle03>1 BNB
                                            <span>February 2, 2019, 9:21am PST</span>
                                    </LedgerTitle03>
                                    <LedgerTitle04>Transaction Submitted
                                            <span>X0456c19d5A61AeA886E6D657EsEF8849565</span>
                                        <i class="fas fa-check-circle"></i>
                                        <button>View transaction</button>
                                    </LedgerTitle04>
                                </LedgerDetSbox02>
                            </LedgerDetSbox>
                        </LedgerDetailBX>
                    </LedgerGridViewBX>
                    <LedgerListViewBX style={viewType ? {} : { display: 'none' }}>

                        <StakingMbox>
                        <StakingText01> Total staked from swapping: <span>125.326545893214505042</span> </StakingText01>
                        <StakingText01> Total withdrawn from staking: <span>133.582844124892004530</span> </StakingText01>
                        <StakingText01> Total withdrawn from APY (10%)<sup>*</sup>: <span>20</span> </StakingText01>
                        <StakingText01> Total staking balance: <span>103.582 829327391232937</span> </StakingText01>
                        <StakingText01> Available for withdraw: <span>31.2536382913728372328</span> </StakingText01> 
                        <StakingText01 className='RedText'>*Total withdrawn from APY; calculated in real time when the contract is triggered to withdraw <span><a href="#">Withdraw</a></span> </StakingText01> 
                        </StakingMbox>

                     </LedgerListViewBX>
                </Gs.Container>
                <SelectTokenPopup isOpen={this.state.popup02} dismiss={() => { this.setState({ popup02: false }) }} />
                <TokenPopup isOpen={this.state.popup01} dismiss={() => { this.setState({ popup01: false }) }} />
                <ReactTooltip effect="solid" className="myTip" />
            </>
        );
    }
}
// Common Style Div 
const FlexDiv = styled.div`
display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;
const AlertTitle01 = styled.div`
    font-size:11px; color:#c32b2d; margin: 0 6px 15px 6px; line-height:18px; 
    & span{ font-size:14px; font-weight:700; margin-bottom:10px; }
    & a{text-decoration:underline; color:#c32b2d;  :hover{color:#fff;}}
`;
const Title01 = styled.div`
  font:400 16px/25px 'Press Start 2P', arial; color:#8e9195; margin: 0 0 15px 0;
`;
const TimerBox = styled.div`
  font:400 50px/60px 'Press Start 2P', arial; color:#febb00; margin: 0 0 15px 0;
  display:flex; align-items: center; justify-content:flex-start;
  span{display: inline-block; background-color: #16191e;  padding: 12px 8px; margin: 0 3px; border-radius: 10px; border: 2px solid #5a5e67; text-indent: 6px;}
`;
const SwapMbox01 = styled.div`
   display: flex; align-items: center; justify-content:center; margin:60px 0 0 0;
`;
const SwapSbox01 = styled(FlexDiv)`
   width: 33.33%; justify-content:flex-start ;
  &.rightAlign{ justify-content:flex-end ;  margin-bottom:auto; }
`;
const SwapSbox02 = styled(FlexDiv)`
   width: 33.33%; padding:15px; flex-direction: column;
`;
const SwapSSbox01 = styled.div`
   width:100%; max-width:430px; background-color: #16191e; border: 2px solid #000; min-height: 430px; padding:18px; border-radius: 10px;
  &.active{ border-color:#febb00; -webkit-box-shadow: 1px 1px 25px 1px rgba(254,187,0,0.4);
box-shadow: 1px 1px 25px 1px rgba(254,187,0,0.4);} 
    &.OrangBX{ 
        background-color:#febb00; border-color:#fff; -webkit-box-shadow: 1px 1px 25px 1px rgba(254,187,0,0.4); box-shadow: 1px 1px 25px 1px rgba(254,187,0,0.4);
    } 
    &.BlackBX{ 
        background-color:#000000; border-color:#fff; -webkit-box-shadow: 1px 1px 25px 1px rgba(0,0,0,0.4); box-shadow: 1px 1px 25px 1px rgba(0,0,0,0.4);
    }
`;
const SwapTitle01 = styled(FlexDiv)`
  font:300 18px/25px 'Press Start 2P', arial; color:#fff; margin-bottom:24px;  position:relative;
  img{margin:0 16px 0 0; } 
  .helpIco{ position:absolute; right:0px;}
`;
const SwapInputMbx = styled(FlexDiv)`
    border:2px solid #545861; border-radius: 10px; min-height:90px; padding:12px 14px;  
    &.WhiteBack{ border-color:#f0f0f0; background-color:#fff;} 
`;
const SwapInputMiniBx = styled(SwapInputMbx)`
border-color:#f0f0f0; background-color:#fff; min-height:46px;
`
const SwapMiniText = styled(SwapInputMiniBx)`
border-color:#f0f0f0; background-color:#fff; min-height:46px; justify-content:flex-start; color:#000; margin-bottom:15px;
`
const MLSubBox = styled(FlexDiv)` 
   width:100%; position:relative; font-size:14px; color:#000; font-weight:700; justify-content:flex-start; 
    span{font-size:12px;  font-weight:400; } 
    button{ display:inline-block; padding:6px; position:absolute; right:6px; :hover{opacity:0.7;}  } 
`;
const ConnectWallText = styled(FlexDiv)`
    width:100%; max-width:260px; font-size:14px; color:#565a69; text-align:center; font-weight:400; 
`;
const SSLinkbox01 = styled(FlexDiv)`
    width:100%;  font-size:12px; color:#565a69; text-align:center; font-weight:400; padding:15px 0; 
    & a{ color:#febb00; margin-left:4px;  :hover{ text-decoration:underline;} }
`;
const SSbx01 = styled(FlexDiv)`
    margin:0 auto 0 0; justify-content: flex-start; color:#8e9195; font-size:14px; width: auto; max-width:40%; 
    span{ width:100%; margin:0 0 6px 0; font-weight:700;
        ${SwapInputMbx}.WhiteBack & {  color:#565a69; }
     }
    input{ background-color:transparent; font-weight:700; font-size:26px; width:100%; height:25px; color:#393d46; border:none;
        ${SwapInputMbx}.WhiteBack & {  color:#dfe0e3; }
     } 
`;
const SSbx02 = styled(FlexDiv)`
    margin:0 0 0 auto; justify-content: flex-end; color:#8e9195; font-size:14px; width: auto; max-width:55%; 
    span{ width:100%; margin:0 0 6px 0; text-align:right; min-height:20px;   }  
    button{
        font-size:16px; color:#fff;  font-weight: 700; display:flex; align-items: center; justify-content:space-between;
        ${SwapInputMbx}.WhiteBack & {
            color:#000;
        }
    }
    button.OrangeBTN{ background-color:#febb00; font-size:16px; color:#fff; font-weight:500; padding:6px 12px; border-radius:6px;
    .fas{font-size:14px;}
    ${SwapInputMbx}.WhiteBack & { 
        color:#fff;
    }
    &:hover{ background-color:#e4a800;}  
    }
    .maxTitle{ background-color:#faecc5;  margin:0 0 0 8px; font-size:12px; border-radius:3px; padding:2px 5px; color:#000000; display:inline-block; } 
    .imgBox{ display:inline-block; margin:0 10px; padding-top:6px; }
    .fas { margin-left:8px;}
`;
const SwapTitle02 = styled(FlexDiv)`
  font:300 18px/25px 'Press Start 2P', arial; color:#565a69;  padding:18px 0; justify-content:flex-start;
    &.Center{ justify-content:center;}
`;
const SwapTitle03 = styled(FlexDiv)`
  font-size:14px; color:#8e9195; font-weight:600; min-height:42px;   justify-content:flex-start;
    span{ margin-left:auto;}
    &.smallText{ color:#565a69; font-size:12px;}
`;
const SwapTitle04 = styled(FlexDiv)`
    font:300 18px/40px 'Press Start 2P', arial; color:#fff; margin-bottom:20px;    justify-content:flex-start;  
    &.smlTitle{  font:300 11px/20px 'IBM Plex Mono', arial; color:#8e9195; margin-top:20px;}
`;
const SwapTitle05 = styled(FlexDiv)`
 font-size:18px; font-weight:700; color:#fff; margin:25px 0 15px 0;    justify-content:flex-start;  
 & .helpIco{ font-size:14px; margin-left:auto; right:3px; top:0px;}
`;
const SwapBTN01 = styled.button`
    display:flex; align-items:center; justify-content: center; color:#fff; border:none; background-color: #ababab;
    font:300 16px/32px 'Press Start 2P', arial; width:100%; padding:12px 12px; border-radius: 10px;
    :hover{  background-color: #878787;}
    &.darkGray{ background-color:#5d6168;
        :hover{  background-color: #484c53;} }
    &.orangeBack{ background-color:#febb00;
    :hover{  background-color: #ecae00; } 
    }
    &.blackBack{ background-color:#000000;
    :hover{  background-color: #302300; } 
    }
`;
const SwapBTN02 = styled.button`
    display:flex; align-items:center; justify-content: flex-start; flex-direction:column; color:#fff; border:none; background-color: #febb00;
     width:152px; height:152px; padding:15px; border-radius:80px;
    .imgBox01{ margin-bottom:12px }  
    :hover{  background-color: #e4a802;} 
`;
const SwapLinkbx = styled(FlexDiv)`
  font-size:12px; color:#ababab; font-weight:400; min-height:46px;  width:100%; max-width: 430px;
  a{color:#ababab; margin:0 6px; 
    :hover{ color:#febb00;}
   }  
`;
const InitialPriceBox = styled(FlexDiv)`
    width:33.33%; flex-direction:column; font-size:12px; font-weight:700; color:#565a69;
`;
const PPClosBTN01 = styled.button`
 width:20px; height:20px; border:0px; outline:none;  color:#fff; background:url(${Xbtn}) left top no-repeat; position:absolute; right:0px;
 transition: 0.5s ease all;
 :hover{ opacity:0.7; transform: rotate(180deg );} 
`;
const Spacer = styled.div`
    width:100%; height:1px; background-color:#e4a700; margin:13px 0;
    ${SwapSSbox01}.BlackBX & { 
        background-color:#242424;
    }
`
const SwapMiniBTN = styled.button` 
    width:100%; display: flex; align-items: center; justify-content:space-between;  font-weight:500;
    & span{display:flex; align-items:center;  
        & .fas{ margin-left:6px;}
    } 
`
const MaxTitle = styled.div` 
background-color:#faecc5;  margin:0 0 0 8px; font-weight:700; font-size:12px; border-radius:3px; padding:2px 5px; color:#000000; display:inline-block; 
`
const SwapDetailBX = styled.div`
    font-size:12px; color:#fff; margin:20px 0;
    & span{ width:100%; display:flex; align-items:center; justify-content:space-between; padding:6px 0; 
    span{ width:auto; padding:0;} 
    } 
    ${SwapSSbox01}.BlackBX & { 
        color:#ababab;
    } 
`
const IconImgbx = styled(FlexDiv)`
width:20px; height:20px; margin:0 8px 0 0; 
 & img{ max-width:100%; height:auto; } 
`
const LedgerTitle01 = styled(FlexDiv)`
    width:100%; justify-content:space-between; font-size:24px; color:#fff; font-weight:700; margin-top:50px;
    & button { color:#fff; font-weight:700;
         
    }
`
const LedgerTiFilter = styled(FlexDiv)`
    justify-content:flex-end; padding:0;
    & button { color:#8e9195; font-size:16px; display:block; max-width:22px; padding:0; margin:0 0 0 12px; 
        :hover{ color:#febb00; }
        &.active{color:#febb00;}
    }
`
const LedgerLinkBX = styled(FlexDiv)`
width:100%; justify-content: flex-start; margin:40px 0 60px 0;
 & button{  font-size:16px; font-weight:700; color:#fff; margin-right:16px; 
            :hover{ color:#febb00;}
            &.active{ color:#febb00;} 
         } 
`
const LedgerDetailBX = styled(FlexDiv)`
    justify-content: flex-start; align-items:flex-start;
`
const LedgerDetSbox = styled(FlexDiv)`
    width:50%; padding:0 15px 0 0; justify-content: flex-start;
    &:nth-last-child(01){ padding:0 0 0 15px;} 
`
const LedgerGridViewBX = styled.div`
`
const LedgerListViewBX = styled.div` 
         padding-top:20px; 
`
const LedgerDetSbox02 = styled(FlexDiv)`
  width:100%; flex-wrap:wrap;  justify-content: flex-start; padding: 30px 0; border-bottom:1px solid #383c45;
`
const LedgerTitle02 = styled(LedgerTitle01)`
    margin:0 0 35px 0;  justify-content:flex-start;
    & span{ font-weight:400; font-size:14px; margin-left:10px; } 
`
const LedgerTitle03 = styled.div`
    margin:0 0 20px 0;  font-size:18px; line-height:26px; font-weight:700;  width:100%;
    & span{ font-weight:400; font-size:12px; display:block;} 
`
const LedgerTitle04 = styled.div`
    padding:0 0 0 27px;  font-size:14px; line-height:26px; font-weight:700;  width:100%; position:relative;
    & span{ font-weight:400; font-size:12px; display:block;}  
    & .fas{position:absolute; left:0px; top:6px; }
    & button{ color:#4848ff; font-size:12px; font-weight:400; padding:0;
        :hover{ text-decoration:underline;}
     }
     &.Small{ padding:0; } 
`
const PendingTag = styled(FlexDiv)`
    color:#c32b2d; font-size:11px; justify-content:space-between; margin:10px 0 0 0;
    & .fas{position:relative; left:auto; top:auto; }
`
const SwappingBOXM = styled(FlexDiv)`
    padding:30px; background-color:#16191e; position:relative; border:2px solid #000000; border-radius:10px ; width:100%; max-width:662px;
    margin:110px 0 80px 0; 
`
const SwapingMbx = styled(FlexDiv)`
    width:50%; padding:0 18px 0 0;  margin-bottom: 20px; position:relative;
    &:nth-child(02){ padding:0 0 0 18px;} 
`
const SwapInputBX = styled(FlexDiv)`
justify-content: flex-start; width:100%; background-color:#16191e; height:56px; border:2px solid #545861; border-radius: 10px; margin-bottom: 20px;
    & input{ width:calc(100% - 54px); background-color:#16191e;  border:none; outline:none; font-size:18px; color:#fff; font-weight:700;} 
    & i.ImgBX{ width:54px; height:54px; display:flex; align-items:center; justify-content:center;
                img{ max-width:100%; height:auto;}
    } 
`
const BswapBTN01 = styled.button`
    border:none; width:168px; height:168px; background-color: #2c2f36; border-radius: 50%; display:flex; align-items:center; justify-content: center; 
    :hover{background-color: #202329; }
`
const Bswaptitle01 = styled(FlexDiv)`
width:100%; padding:30px 4px 20px 4px; font:normal 21px/30px 'Press Start 2P', arial; color:#fff; 
`
const SwappingLink01 = styled(FlexDiv)`
 width:1px; height:100%; background-color:#393d46; position:absolute; right:0px;  
    & button{ display:block; width:60px; padding:0px; margin:0px; height:60px; position:absolute; background:url(${ArrowICON}) 50% 50% no-repeat #16191e; z-index:1; transition:300ms;
    :hover{ transform:scale(1.2);}
     }
`
const SwapText01 = styled.div`
    position:absolute; width:100%; top:calc(100% + 12px); font-size:12px; color:#fff; text-align:center;

`

const StakingMbox = styled.div`
    width:100%; 
`

const StakingText01 = styled(FlexDiv)`
    width:100%; justify-content:space-between; font-size:14px; color:#ffffff; padding:12px; border-bottom:1px solid #383c45;

    & span{ margin-left:auto; color:#4848ff; font-size:18px;}
    & sup{ font-size:12px; color:#c32b1c;}

    &.RedText{ font-size:11px; color:#c32b1c; border-bottom:0px;
        a{ color:#c32b1c; font-size:11px; :hover{text-decoration:underline;}}
    }

`

export default Page06;